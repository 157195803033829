import './NewsPart.scss'

import PuxLink from 'Shared/utils/PuxLink'
import React, { FunctionComponent } from 'react'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Root/Shared/components/content/mediaImage/PuxMediaSelector'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import getReadingDuration from 'Shared/utils/getReadingDuration'
import { getLocalizedUrl } from 'Shared/utils/localeURL'
import { t } from 'ttag'

import PuxButton from '../../PuxButton/PuxButton'
import { PuxVideoType } from '../../PuxVideo/PuxVideo'

export interface NewsPartType {
  contentType: 'EasySoftwareNews'
  contentItemId: string
  path: string
  displayText: string
  createdUtc: string
  newsPerex: PuxWysiwygHtmlBodyType
  newsContent: PuxWysiwygHtmlBodyType
  newsImage: PuxMediaSelectorType
  puxVideo: PuxVideoType
}

const NewsPart: FunctionComponent<NewsPartType> = (props) => {
  const date = new Date(props.createdUtc)
  const minutes = getReadingDuration([
    props.newsPerex.html,
    props.newsContent.html,
  ])

  const hasImage =
    props.newsImage?.mediaSelectorImage?.resizePaths?.length &&
    props.newsImage?.mediaSelectorImage?.resizePaths[0]?.length > 0
  const hasImageClass = hasImage ? `` : ` no-image`

  return (
    <div className='NewsItem'>
      <div className={`NewsItem-image${hasImageClass}`}>
        <PuxLink to={`${getLocalizedUrl(props.path)}`}>
          <PuxMediaSelector {...props.newsImage} height={275} width={449} />
        </PuxLink>
      </div>

      <div className='NewsItem-parameters'>
        <div className='NewsItem-date'>{date.toLocaleDateString()}</div>
        <div className='NewsItem-time'>
          {`${minutes} ${Number.parseInt(minutes) > 1
            ? t`Redmine.News.MinutesRead`
            : t`Redmine.News.MinuteRead`
            }`}
        </div>
      </div>

      <h5 className='NewsItem-heading'>
        <PuxLink to={`${getLocalizedUrl(props.path)}`}>{props.displayText}</PuxLink>
      </h5>

      <div className='NewsItem-perex'>
        <PuxWysiwyg wysiwygId={props.contentItemId} content={props.newsPerex} inline />
      </div>

      <PuxButton
        buttonType={`Button--link`}
        buttonSize={`Button--large`}
        buttonColor={`Button--blue`}
        buttonLink={{
          url: [props.path],
          internal: [true],
          text: [t`Redmine.News.ReadMore`],
        }}
      />
    </div>
  )
}

export default NewsPart

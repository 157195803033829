//@ts-ignore
import LogoLeft from 'EasyRedmineGatsby/images/logo-redmine-footer.svg'
//@ts-ignore
import { ReactComponent as Logo } from 'EasyRedmineGatsby/svg/ER-logo.svg'
import PuxLink from 'Shared/utils/PuxLink'
import React from 'react'
import PuxSocials from 'Shared/components/PuxSocials/PuxSocials'
//@ts-ignore
import circle from 'Shared/images/Circle_2.svg'
import { getLocalizedUrl } from 'Shared/utils/localeURL'
import { t } from 'ttag'
import { IFooterData } from 'Shared/queries/layout-queries'

interface ContentMenuItem {
  contentType: 'ContentMenuItem'
  displayText: string
  render: string
}
interface LinkMenuItem {
  contentType: 'LinkMenuItem'
  linkMenuItem: {
    name: string
    url: string
  }
}

type MenuItem = ContentMenuItem | LinkMenuItem
interface CategoryItem {
  categoryItemName: string
  menuItemsList: {
    menuItems: MenuItem[]
  }
}
interface FooterData {
  menuItems: CategoryItem[]
}

interface FooterPropsType {
  footerData: IFooterData
}

const getLink = (item: MenuItem) => {
  switch (item.contentType) {
    case `ContentMenuItem`:
      const itemData = JSON.parse(item.render)
      return (
        <PuxLink to={`${getLocalizedUrl(itemData.url)}`}>{item.displayText}</PuxLink>
      )
    case `LinkMenuItem`:
      return <a href={item.linkMenuItem.url}>{item.linkMenuItem.name}</a>
  }
}

const Footer: React.FunctionComponent<FooterPropsType> = (props) => {
  const footerData: FooterData = props.footerData.menu[0].menuItemsList
  const year = `${new Date().getFullYear()}`

  if (!footerData || !footerData?.menuItems) {
    console.log(footerData)
    return null
  }

  return (
    <footer className='Footer'>
      <div className='Footer-circle'>
        <img src={circle} />
      </div>
      <div className='pux-container'>
        <div className='Footer-navigation'>
          {footerData.menuItems?.map((cat, catIdx) => (
            <div className='FooterCol' key={catIdx}>
              <div className='FooterCol-content'>
                <h4 className='FooterCol-title'>{cat.categoryItemName}</h4>
                <ul className='FooterCol-list'>
                  {cat.menuItemsList?.menuItems &&
                    cat.menuItemsList.menuItems.length &&
                    cat.menuItemsList.menuItems?.map((item, itemIdx) => (
                      <li key={itemIdx}>{getLink(item)}</li>
                    ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        <div className='FooterBrand'>
          <div className='FooterLogo'>
            <Logo />
          </div>

          <div className='FooterSocial'>
            {/* <p className='FooterSocial-text'>{t`Redmine.Footer.SocialMedia`}</p> */}
            <PuxSocials
              urlFacebook={t`Redmine.Social.Facebook`}
              urlTwitter={t`Redmine.Social.Twitter`}
              urlLinkedin={t`Redmine.Social.Linkedin`}
              urlYoutube={t`Redmine.Social.Youtube`}
            />
          </div>

          <div className='FooterPartners'>
            <div className='FooterPartners-right'>
              <img
                src={LogoLeft}
                alt={t`Redmine.Footer.LogoLeft`}
              // width={170}
              // height={38}
              />
            </div>
          </div>
        </div>

        <div className='Footer-foot'>
          <div className='Footer-copyright'>
            {t`Redmine.Footer.Copyright`.replace(`%currentYear%`, year)}
            <span className='Footer-sitemap'>
              <PuxLink to={`/sitemap.xml`}>{t`Redmine.Footer.SiteMap`}, XML</PuxLink>
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

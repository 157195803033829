import './NewsDetail.scss'

//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import React, { FunctionComponent } from 'react'
import { HeadProps } from 'gatsby'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import PuxMediaSelector from 'Shared/components/content/mediaImage/PuxMediaSelector'
import EasySoftwareBreadcrumbs from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import PuxContent from 'Shared/components/PuxContent/PuxContent'
import PuxStructuredData from 'Shared/components/PuxStructuredData/PuxStructuredData'
import PuxVideo from 'Shared/components/PuxVideo/PuxVideo'
import getReadingDuration from 'Shared/utils/getReadingDuration'
import { msgid, ngettext, t } from 'ttag'
import PuxMetaTagsGatsby from 'Shared/utils/PuxMetaTagsGatsby'
import { GenericPageProps, IEasySoftwareNewsPage } from 'Shared/queries/page-queries'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'
import EasySoftwareEditorContainer from 'Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'

function getNewsJson(item: IEasySoftwareNewsPage) {
  const json = {
    '@context': `https://schema.org`,
    '@type': `NewsArticle`,
    headline: item.displayText,
    image: [
      item.newsImage?.mediaSelectorImage.resizePaths[0] ?? ``,
      item.newsImage?.mediaSelectorImage.resizePathsTablet[0] ?? ``,
      item.newsImage?.mediaSelectorImage.resizePathsMobile[0] ?? ``,
    ],
    datePublished: item.createdUtc,
    dateModified: item.modifiedUtc ?? ``,
  }

  return json
}

const NewsDetail: FunctionComponent<GenericPageProps<IEasySoftwareNewsPage>> = (props) => {
  const pageData = props.pageContext.pageData
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData
  const date = new Date(pageData.createdUtc)
  const minutes = getReadingDuration([
    pageData.newsPerex.html,
    pageData.newsContent.html,
  ])

  const widgetData = props.pageContext.widgets?.widgetZone
  const breadcrumbsData = props.pageContext.breadcrumbs
  const editorPath = `widgetZone,widgets`;

  return (
    <Layout localizedPath={pageData.localization?.localizations} footerData={footerData} headerData={headerData}>
      <PuxStructuredData structuredData={getNewsJson(pageData)} />
      <div className='NewsDetail'>
        <EasySoftwareBreadcrumbs items={breadcrumbsData} />
        <div className='Container'>
          <PuxContent
            contentModifier='newsDetail'
            sideCards={props.pageContext.sideCardWidgets}
          >
            <h2 className='NewsDetail-title'>{pageData.displayText}</h2>

            <div className='NewsDetail-meta'>
              <div className='NewsDetail-date NewsDetail-parameter'>
                {date.toLocaleDateString()}
              </div>

              <div className='NewsDetail-time NewsDetail-parameter'>
                {ngettext(
                  msgid`${minutes} minute`,
                  `${minutes} minutes`,
                  minutes
                )}
              </div>

              {pageData.newsAuthor && (
                <div className='NewsDetail-author NewsDetail-parameter'>
                  {pageData.newsAuthor}
                </div>
              )}
            </div>

            <div className='NewsDetail-perex perex'>
              <EasySoftwareEditorContainer wysiwygId={pageData.contentItemId + '-perex'} pageContentItemId={pageData.contentItemId} content={pageData.newsPerex} editorPath={`newsPerex,html`} />
            </div>

            <div className='NewsDetail-image'>
              {pageData.puxVideo?.videoYTID.html && (
                <PuxVideo {...pageData.puxVideo} />
              )}
              {!pageData.puxVideo?.videoYTID.html && (
                <PuxMediaSelector {...pageData.newsImage} disableLazyload />
              )}
            </div>

            <div className='NewsDetail-content'>
              <EasySoftwareEditorContainer wysiwygId={pageData.contentItemId + '-content'} pageContentItemId={pageData.contentItemId} content={pageData.newsContent} editorPath={`newsContent,html`} />
            </div>

            <WidgetBuilder widgetBuilderData={widgetData} pageContentItemId={pageData.contentItemId} editorPath={editorPath} />
          </PuxContent>
        </div>
      </div>
    </Layout>
  )
}

export default NewsDetail

export function Head(props: HeadProps<never, IPageContext<IEasySoftwareNewsPage>>) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  if (metaTags?.metaTagsOGImage?.resizePaths?.length) {
    metaTags.metaTagsOGImage = pageData.newsImage?.mediaSelectorImage
  }

  return <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
}
import 'react-toastify/dist/ReactToastify.css';
import './EasySoftwareEditorContainer.scss'

import React, { FunctionComponent, useState } from 'react'
import { toast } from 'react-toastify';
import { useEditorStatus } from 'Root/Shared/utils/edit/useEditorStatus';

import EasySoftwareWysiwygEditor from '../EasySoftwareWysiwygEditor/EasySoftwareWysiwygEditor'
import PuxWysiwyg, { PuxWysiwygHtmlBodyType } from '../PuxWysiwyg/PuxWysiwyg'

declare global {
  interface Window {
    pux: any
  }
}

export interface EasySoftwareEditorContainerType {
  content: PuxWysiwygHtmlBodyType,
  wysiwygId?: string,
  className?: string,
  inline?: boolean,
  small?: boolean,
  pageContentItemId: string,
  //Used in news, to set unique id for perex and content wysiwygs
  editorPath: string
  saveText?: boolean
}

const EasySoftwareEditorContainer: FunctionComponent<EasySoftwareEditorContainerType> = (
  props
) => {
  const isEditor = useEditorStatus()
  const [editorInitialized, setEditorInitialized] = useState(false)
  const [wysiwygValue, setWysiwygValue] = useState(props.content)

  if (!props.wysiwygId) {
    props.wysiwygId = props.pageContentItemId
  }

  async function saveEditor(value, EditorRef, EditorButtonRef) {
    if (!props.pageContentItemId || !props.editorPath) {
      console.log(`DEBUG pageContentItemId: ${props.pageContentItemId}`)
      console.log(`DEBUG wysiwygId: ${props.wysiwygId}`)
      console.log(`DEBUG editorPath: ${props.editorPath}`)
      toast.error(`Editor is missing parameters.`)
      return
    }

    await fetch((`${process.env.GATSBY_ORCHARD_API_URL}/puxapi/contentitem/editfield`), {
      method: `POST`,
      headers: {
        'Content-Type': `application/json`,
      },
      body: JSON.stringify(
        {
          contentitemid: props.pageContentItemId,
          path: props.editorPath,
          value: props.saveText ? value.replace(/<(\/*)p[^>]*>/g, ``) : value
        }
      )
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(`Editor saved.`)
          EditorRef.current?.classList.remove(`WysiwygEditor--error`)
          EditorButtonRef.current?.classList.remove(`WysiwygEditor-button--changed`)
          window.pux = window.pux || {}
          window.pux.activeWysiwyg = false
          setWysiwygValue({ html: props.saveText ? value.replace(/<(\/*)p[^>]*>/g, ``) : value })
          setEditorInitialized(false)
        }
        if (response.status === 500 || response.status === 400) {
          toast.error(`Editor error 500.`)
          EditorRef.current?.classList.add(`WysiwygEditor--error`)
        }
      })
      .catch((error) => {
        console.log(`Error saving editor data:`, error)
        toast.error(`Editor error.`)
        EditorRef.current?.classList.add(`WysiwygEditor--error`)
      })
  }

  const closeEditor = () => {
    setEditorInitialized(false)
    window.pux = window.pux || {}
    window.pux.activeWysiwyg = false
  }

  if (isEditor && editorInitialized) {
    return (
      <>
        <EasySoftwareWysiwygEditor content={wysiwygValue} wysiwygId={props.wysiwygId ?? props.pageContentItemId} className={props.className} inline={props.inline} small={props.small} closeEditor={closeEditor} saveEditor={saveEditor} />
      </>
    )
  } else if (isEditor) {
    return (
      <div className="Editor-container" onClick={() => {
        if (window?.pux?.activeWysiwyg) {
          toast.warning(`Close active editor, to open new instance.`)
          return
        }

        window.pux = window.pux || {}
        window.pux.activeWysiwyg = true

        setEditorInitialized(true)
      }}>
        <PuxWysiwyg content={wysiwygValue} wysiwygId={props.wysiwygId ?? props.pageContentItemId} className={props.className} inline={props.inline} small={props.small} />
      </div>
    )
  } else {
    return (
      <div>
        <PuxWysiwyg content={wysiwygValue} wysiwygId={props.wysiwygId ?? props.pageContentItemId} className={props.className} inline={props.inline} small={props.small} />
      </div>
    )
  }
}

export default EasySoftwareEditorContainer

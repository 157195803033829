import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/jetbrains-mono/400.css'
import './src/sass/style.scss'
// import './src/templates/Pricing/PricingPage.scss'
import 'Shared/sass/style.scss'

import {
  MauticOnRouteChange,
  MauticOnWebLoad,
} from 'Shared/mautic/EasyRedmine/ERMauticScripts'
import { initDynamicRS } from 'Shared/utils/dynamicRS'
import {
  addEditorCookie,
  resetEditorCookie,
} from 'Shared/utils/edit/useEditorStatus.tsx'
import {
  checkHash,
  isBrowser,
  scroller,
} from 'Shared/utils/PuxAnchorLink/puxAnchorUtils'
import { removeAllParamsWhenUTM } from 'Shared/utils/removeUTM'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onClientEntry = () => {
  if (document.querySelector(`.NotFound`)) {
    removeAllParamsWhenUTM(window.location.href)
  }
  initDynamicRS()
  resetEditorCookie()

  // if (process.env.GATSBY_ACTIVE_ENV === 'stage' || process.env.GATSBY_ACTIVE_ENV === 'production') {
  //   document.addEventListener('DOMContentLoaded', () => {
  //     /** init gtm after 3500 seconds - this could be adjusted */
  //     setTimeout(initGTM, 3500);
  //   });

  //   document.addEventListener('scroll', initGTMOnEvent);
  //   document.addEventListener('mousemove', initGTMOnEvent);
  //   document.addEventListener('touchstart', initGTMOnEvent);

  //   function initGTMOnEvent(event) {
  //     initGTM();
  //     event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
  //   }

  //   function initGTM() {
  //     // let dataLayer = []
  //     if (window.gtmDidInit) {
  //       return false;
  //     }
  //     window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.
  //     const script = document.createElement('script');
  //     script.type = 'text/javascript';
  //     script.async = true;
  //     script.onload = () => {
  //       if (dataLayer) {
  //         dataLayer.push({ event: 'gtm.js', 'gtm.start': new Date().getTime(), 'gtm.uniqueEventId': 0 });
  //       }
  //     }; // this part ensures PageViews is always tracked
  //     script.src = 'https://www.googletagmanager.com/gtm.js?id=' + process.env.GATSBY_GTMID;
  //     document.head.appendChild(script);
  //   }
  // }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onInitialClientRender = () => {
  MauticOnWebLoad()
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const onRouteUpdate = ({ location }) => {
  MauticOnRouteChange()

  if (location.pathname.startsWith(`/edit`)) {
    addEditorCookie()
  }

  function iOS() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  }

  if (iOS()) {
    document.querySelector('#___gatsby').classList.add('is-iOS')
  }

  let windowHash
  let offset = 0
  let duration = 800

  if (isBrowser) {
    window.gatsby_scroll_offset = offset
    window.gatsby_scroll_duration = duration
    windowHash = window.gatsby_scroll_hash
  }

  windowHash ? scroller(windowHash, offset) : checkHash(location, offset)

  if (isBrowser && windowHash) {
    window.gatsby_scroll_hash = undefined
  }
}

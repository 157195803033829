import React, { FunctionComponent } from 'react'
import { PuxWysiwygHtmlBodyType } from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { getLocalizedData } from 'Shared/utils/localeURL'

import { MediaSelectorImageType } from '../components/content/mediaImage/PuxPicture'
import getHrefLangsForHelmet from './getHreflangsForHelmet'
import { localizedPathType } from '../components/PuxLanguageSelector/PuxLanguageSelector'

const localizedConfigData = getLocalizedData(
  process.env.LOCALE ?? ``,
  process.env.PROJECT ?? ``
)
const activeEnv = process.env.GATSBY_ACTIVE_ENV ?? ``

const ER_TITLE_POSTFIX = ` - Easy Redmine`
const EP_TITLE_POSTFIX = ` - Easy Project`
const ES_TITLE_POSTFIX = ` - Easy Software`
const getTitlePostfix = (): string => {
  switch (process.env.PROJECT) {
    case `EasyRedmineGatsby`:
      return ER_TITLE_POSTFIX
    case `EasyProjectGatsby`:
      return EP_TITLE_POSTFIX
    case `EasySoftwareGatsby`:
      return ES_TITLE_POSTFIX
    default:
      return ``
  }
}

export interface PuxMetaTagsType {
  metaTagsTitle?: PuxWysiwygHtmlBodyType
  metaTagsRobots?: string
  metaTagsCanonicalUrl?: PuxWysiwygHtmlBodyType
  metaTagsKeywords?: PuxWysiwygHtmlBodyType
  metaTagsDescription?: PuxWysiwygHtmlBodyType
  metaTagsOGTitle?: PuxWysiwygHtmlBodyType
  metaTagsOGDescription?: PuxWysiwygHtmlBodyType
  metaTagsOGImage?: MediaSelectorImageType
  metaTagsImage?: MediaSelectorImageType
  localizedPaths: localizedPathType[]
}

const PuxMetaTags: FunctionComponent<PuxMetaTagsType> = (props) => {
  const canonicalPath =
    localizedConfigData.domains[activeEnv] + props.metaTagsCanonicalUrl?.html
  const pathQuery =
    typeof document === `undefined` ? `` : window.location.search
  let metaTagsOGTitle = props.metaTagsOGTitle
  const metaTagsTitle = props.metaTagsTitle
  const metaTagsTitlePostfix = getTitlePostfix()
  let metaTagsOGDescription = props.metaTagsOGDescription
  const metaTagsDescription = props.metaTagsDescription
  /* eslint-disable */
  if (!metaTagsOGTitle?.html) {
    metaTagsOGTitle = metaTagsTitle
  }

  if (!metaTagsOGDescription?.html) {
    metaTagsOGDescription = metaTagsDescription
  }

  const hreflangs = getHrefLangsForHelmet(props.localizedPaths ?? [])

  var metapath = ``
  if (props.metaTagsOGImage?.resizePaths?.length) {
    metapath = props.metaTagsOGImage.resizePaths[0].substring(
      0,
      props.metaTagsOGImage.resizePaths[0].indexOf(`?`) ?? props.metaTagsOGImage.resizePaths[0].length
    )
  }

  return (
    <>
      {metaTagsTitle?.html && (
        <title>{metaTagsTitle.html.replace(/&amp;/g, '&') + metaTagsTitlePostfix}</title>
      )}

      {props.metaTagsRobots && (
        <meta name='robots' content={props.metaTagsRobots} />
      )}

      <meta
        httpEquiv='content-language'
        content={localizedConfigData.isoCode}
      />

      {props.metaTagsCanonicalUrl?.html && (
        <link
          rel='canonical'
          href={
            canonicalPath.endsWith(`/`) ?
              canonicalPath.slice(0, -1) + pathQuery :
              canonicalPath + pathQuery
          }
        />
      )}

      {metaTagsDescription?.html && (
        <meta
          name='description'
          content={metaTagsDescription.html.replace(/(<([^>]+)>)/gi, ``)}
        />
      )}

      {props.metaTagsKeywords?.html && (
        <meta name='keywords' content={props.metaTagsKeywords.html} />
      )}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@EasyRedminecom" />
      <meta name="twitter:creator" content="@EasyRedminecom" />
      <meta property="og:type" content="company" />

      {metaTagsOGTitle?.html && (
        <meta
          property='og:title'
          content={metaTagsOGTitle.html.replace(/&amp;/g, '&') + metaTagsTitlePostfix}
        />
      )}

      {metaTagsOGTitle?.html && (
        <meta
          name="twitter:title"
          content={metaTagsOGTitle.html.replace(/&amp;/g, '&') + metaTagsTitlePostfix}
        />
      )}

      {metaTagsOGDescription?.html && (
        <meta
          property='og:description'
          content={metaTagsOGDescription.html.replace(/(<([^>]+)>)/gi, ``)}
        />
      )}

      {metaTagsOGDescription?.html && (
        <meta
          name="twitter:description"
          content={metaTagsOGDescription.html.replace(/(<([^>]+)>)/gi, ``)}
        />
      )
      }

      {
        metapath !== `` && (
          <meta
            property='og:image'
            content={metapath}
          />
        )
      }

      {
        !props.metaTagsOGImage?.resizePaths?.length && (
          <meta
            property='og:image'
            content={localizedConfigData.domains[activeEnv] + `/og-default.png`}
          />
        )
      }


      {hreflangs?.map((hreflang, index) => {
        return (
          <link
            rel='alternate'
            hrefLang={hreflang.isoCode}
            href={hreflang.path}
            key={index}
          />
        )
      })}
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href={process.env.GATSBY_WEBSITE_URL + `/apple-touch-icon.png`}
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href={process.env.GATSBY_WEBSITE_URL + `/favicon-32x32.png`}
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href={process.env.GATSBY_WEBSITE_URL + `/favicon-16x16.png`}
      />
      <link
        rel='manifest'
        href={process.env.GATSBY_WEBSITE_URL + `/site.webmanifest`}
      />
      <link
        rel='mask-icon'
        href={process.env.GATSBY_WEBSITE_URL + `/safari-pinned-tab.svg`}
        color='#0080ff'
      />
      <meta name='msapplication-TileColor' content='#ffffff' />
      <meta name='theme-color' content='#ffffff' />
      <body className={process.env.LOCALE !== `cs` ? `ENUS` : `CSCZ`} />
    </>
  )
  /* eslint-enable */
}

export default PuxMetaTags

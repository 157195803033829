import './PuxLanguageSelector.scss'

import React, { FunctionComponent, useEffect, useRef } from 'react'
import {
  getIsoCodesWithPrefixPublic,
  getLocalizedUrl,
  isoCodePrefix,
} from 'Shared/utils/localeURL'
import { t } from 'ttag'

import FlagBg from './flags/bg.svg'
import FlagBr from './flags/br.svg'
import FlagCs from './flags/cs.svg'
import FlagDe from './flags/de.svg'
import FlagDk from './flags/dk.svg'
import FlagEn from './flags/en.svg'
import FlagEs from './flags/es.svg'
import FlagFr from './flags/fr.svg'
import FlagGr from './flags/gr.svg'
import FlagHu from './flags/hu.svg'
import FlagIl from './flags/il.svg'
import FlagIt from './flags/it.svg'
import FlagJp from './flags/jp.svg'
import FlagKr from './flags/kr.svg'
import FlagNl from './flags/nl.svg'
import FlagNo from './flags/no.svg'
import FlagPl from './flags/pl.svg'
import FlagRu from './flags/ru.svg'
import FlagSe from './flags/Se.svg'
import FlagTr from './flags/tr.svg'
import FlagRo from './flags/ro.svg'
import FlagUa from './flags/ua.svg'
import FlagFi from './flags/fi.svg'
import FlagEu from './flags/eu.svg'
import FlagId from './flags/id.svg'
import FlagVn from './flags/vn.svg'
import FlagCn from './flags/cn.svg'

const getFlagUrl = (lang: string) => {
  switch (lang) {
    case `fr`:
      return FlagFr
    case `kr`:
      return FlagKr
    case `ru`:
      return FlagRu
    case `de`:
      return FlagDe
    case `es`:
      return FlagEs
    case `br`:
      return FlagBr
    case `jp`:
      return FlagJp
    case `it`:
      return FlagIt
    case `dk`:
      return FlagDk
    case `bg`:
      return FlagBg
    case `gr`:
      return FlagGr
    case `hu`:
      return FlagHu
    case `il`:
      return FlagIl
    case `it`:
      return FlagIt
    case `nl`:
      return FlagNl
    case `no`:
      return FlagNo
    case `tr`:
      return FlagTr
    case `se`:
      return FlagSe
    case `pl`:
      return FlagPl
    case `cs`:
      return FlagCs
    case `ro`:
      return FlagRo
    case `ua`:
      return FlagUa
    case `fi`:
      return FlagFi
    case `eu`:
      return FlagEu
    case `id`:
      return FlagId
    case `vn`:
      return FlagVn
    case `cn`:
      return FlagCn
    default:
      return FlagEn
  }
}

interface PuxLanguageSelectorType {
  localizedPaths?: localizedPathType[]
  selectorType?: 'default' | 'nativeDropdown'
}

export interface localizedPathType {
  path: string
  published: boolean
  localization: {
    culture: string
  }
}

const PuxLanguageSelector: FunctionComponent<PuxLanguageSelectorType> = (
  props
) => {

  const project: string = process.env.PROJECT
    ? process.env.PROJECT
    : `EasyRedmineGatsby`
  const locales = getIsoCodesWithPrefixPublic(true, project?.endsWith("AI") ? project.replace("AI", "") : project)
  const localesAI = getIsoCodesWithPrefixPublic(true, project?.endsWith("AI") ? project : project + "AI")
  const activeLang: string = process.env.LOCALE ? process.env.LOCALE : `en`
  const classList = ['LanguageSelector']
  const langWrapper = useRef<HTMLDivElement>(null)

  const getLocalizedPath = (locale: isoCodePrefix): string => {
    const localizedPaths = props.localizedPaths
    const defaultPath = `${locale.domain}/${locale.prefix}`

    if (!localizedPaths) {
      return defaultPath
    }

    const localizedPath = localizedPaths.filter(
      (localizedPath) => localizedPath.localization.culture === locale.isoCode
    )

    if (!localizedPath.length) {
      return defaultPath
    }

    const localizedUrl = getLocalizedUrl(localizedPath[0].path)

    if (
      localizedUrl.startsWith(`http://`) ||
      localizedUrl.startsWith(`https://`)
    ) {
      return localizedUrl
    }

    return locale.domain + localizedUrl
  }

  useEffect(() => {
    document.addEventListener(`click`, (e) => {
      if (
        langWrapper.current &&
        !langWrapper.current.contains(e.target as Node)
      ) {
        if (langWrapper.current.classList.contains(`is-opened`)) {
          langWrapper.current.classList.remove(`is-opened`)
        }
      }
    })
  }, [])

  const handleLangSelect = (value: string) => {
    if (!value) {
      return
    }

    const parsedString = value.split('|')

    if (typeof window !== "undefined") {
      if (parsedString[0] === 'Lang') {
        const localeObject: isoCodePrefix = {
          locale: parsedString[1],
          domain: parsedString[2],
          isoCode: parsedString[3],
          prefix: parsedString[4],
        }
        window.location.href = getLocalizedPath(localeObject)
      }
    }
  }

  if (props.selectorType === 'nativeDropdown') {
    return (
      <div className={classList.join(' ')}>
        <img
          className={`LanguageSelector-flag`}
          src={getFlagUrl(activeLang)}
          alt={activeLang}
        />
        <select className='PuxSelect' onChange={e => handleLangSelect(e.target.value)}>
          <option disabled>{t`Redmine.LanguageSelector.Language`}</option>
          {locales.reverse().map((locale, idx) => (
            <option
              key={idx}
              value={'Lang|' + locale.locale + '|' + locale.domain + '|' + locale.isoCode + '|' + locale.prefix}
              selected={activeLang === locale.locale}
            >{locale.isoCode.split('-')[1]}</option>
          ))}
          {localesAI?.length > 0 && (
            <>
              <option disabled>{t`Redmine.LanguageSelector.Automatic.Language`}</option>
              {localesAI.map((localeAI, idx) => (
                <option
                  key={idx}
                  value={'Lang|' + localeAI.locale + '|' + localeAI.domain + '|' + localeAI.isoCode + '|' + localeAI.prefix}
                  selected={activeLang === localeAI.locale}
                >{localeAI.isoCode.split('-')[1]}</option>
              ))
              }
            </>
          )}
        </select>
      </div>
    )
  }

  return (
    <div className={classList.join(' ')}
      ref={langWrapper}
    >
      <div
        className={`LanguageSelector-icon`}
        onClick={() => {
          const thisWrapper = langWrapper.current

          if (!thisWrapper) return

          if (thisWrapper.classList.contains(`is-opened`)) {
            thisWrapper.classList.remove(`is-opened`)
          } else {
            thisWrapper.classList.add(`is-opened`)
          }
        }}
      >
        <img
          className={`LanguageSelector-flag`}
          src={getFlagUrl(activeLang)}
          alt={activeLang}
        />
      </div>

      <div
        className={`LanguageSelector-dialog`}
      >
        <div>
          <div className={`LanguageSelector-heading`}>
            {t`Redmine.LanguageSelector.Language`}
          </div>

          <ul className={`LanguageSelector-items`}>
            {locales.reverse().map((locale, idx) => (
              <li key={idx}>
                <a
                  className={activeLang === locale.locale ? `is-active` : ``}
                  href={getLocalizedPath(locale)}
                >
                  <img
                    className={`LanguageSelector-flag`}
                    src={getFlagUrl(locale.locale)}
                    alt={locale.locale}
                  />
                </a>
              </li>
            ))}
          </ul>
        </div>
        {localesAI?.length > 0 && (
          <div>
            <div className={`LanguageSelector-heading`}>
              {t`Redmine.LanguageSelector.Automatic.Language`}
            </div>

            <ul className={`LanguageSelector-items`}>
              {localesAI.reverse().map((localeAI, idx) => (
                <li key={idx}>
                  <a
                    className={activeLang === localeAI.locale ? `is-active` : ``}
                    href={getLocalizedPath(localeAI)}
                  >
                    <img
                      className={`LanguageSelector-flag`}
                      src={getFlagUrl(localeAI.locale)}
                      alt={localeAI.locale}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default PuxLanguageSelector

import './EasySoftwareAccordion.scss'

import React, { FunctionComponent, useState } from 'react'

import { EasySoftwareAccordionItemType } from './EasySoftwareAccordionItemHeading'
import EasySoftwareAccordionItemContent from './EasySoftwareAccordionItemContent'
import EasySoftwareAccordionItemHeading from './EasySoftwareAccordionItemHeading'

export interface EasySoftwareAccordionType {
  pageContentItemId: string
  editorPath: string
  accordionContentPosition: boolean,
  accordionContentPositionDesktop: boolean,
  accordionContentLocation: boolean,
  accordionBackground: string,
  bag: {
    contentItems: EasySoftwareAccordionItemType[]
  }
}

const EasySoftwareAccordion: FunctionComponent<EasySoftwareAccordionType> = (
  props
) => {

  const [accordionOpen, setAccordionOpen] = useState(0)

  return (
    <div className={`Accordion` + (props.accordionContentPositionDesktop ? ` Accordion--reverse` : ``) + (` Accordion--` + props.accordionBackground) + (props.accordionContentLocation ? ` Accordion--inside` : ``) + (props.accordionContentPosition ? `` : ` Accordion--above`)}>
      <div className='Accordion-headings'>
        {props.bag &&
          props.bag.contentItems &&
          props.bag.contentItems.map((item, index) => (
            <EasySoftwareAccordionItemHeading accordionContentPosition={props.accordionContentPosition} accordionContentLocation={props.accordionContentLocation} key={item?.contentItemId} data={item} index={index} isOpen={index === accordionOpen ? true : false} setAccordionOpen={setAccordionOpen} pageContentItemId={props.pageContentItemId} editorPath={props.editorPath + `,cid-${item.contentItemId},easySoftwareAccordionItem,accordionItemContent,html`} />
          ))}
      </div>
      <div className='Accordion-contents'>
        {props.bag &&
          props.bag.contentItems &&
          props.bag.contentItems.map((item, index) => (
            <EasySoftwareAccordionItemContent key={item?.contentItemId} data={item} index={index} isOpen={index === accordionOpen ? true : false} setAccordionOpen={setAccordionOpen} />
          ))}
      </div>
    </div>
  )
}

export default EasySoftwareAccordion

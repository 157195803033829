/* eslint-disable @typescript-eslint/no-explicit-any */
import { getWidgetQuery } from '../../queries/widget-queries'
import { getBuilderService } from './puxBuilderService'
import { puxGraphqlHelper } from './puxGraphqlHelper'

const SIDE_CARD_CONTENT_TYPE = `EasySoftwareSideCard`
const SIDE_CARD_CONTENT_FIELD = `PuxCategory`

const getSideCardWidgets = async (
    preferedContentLinkCulture,
    nodeTermContentItemId
) => {
    const { locale } = getBuilderService()

    const sideCardWidgets: any[] = []
    const taxonomyQuery = `{
        orchard {
          taxonomyContentItem(
            where: {culture: "${locale.isoCode}", termContentItemId: "${nodeTermContentItemId}", contentType: "${SIDE_CARD_CONTENT_TYPE}", contentField: "${SIDE_CARD_CONTENT_FIELD}"}
            ) {
              contentItemId
            }
          }
        }`

    const taxonomyNodes = await puxGraphqlHelper<any>(
        taxonomyQuery,
        {
            errorMetadata: {
                fromFunction: `Error while running GraphQL query for taxonomyNodes in getSideCardData.ts`,
                metadata: `
                    line: 26, 
                    termContentItemId: "${nodeTermContentItemId}", 
                    query: "${taxonomyQuery}"`
            }
        }
    )

    if (taxonomyNodes.errors) {
        return sideCardWidgets
    }

    if (!taxonomyNodes || !taxonomyNodes.taxonomyContentItem) {
        return sideCardWidgets
    }

    const taxonomyNodeContentItem =
        taxonomyNodes.taxonomyContentItem[0]

    if (!taxonomyNodeContentItem) {
        return sideCardWidgets
    }

    const taxonomyNodeContentItemId = taxonomyNodeContentItem.contentItemId

    const sideCardQuery = `{
    orchard {
        easySoftwareSideCard (
          where: {contentItemId: "${taxonomyNodeContentItemId}"}
          ) {
            widgetZone {
              widgets {
                contentType
                contentItemId
                %widgetFragmentsPlaceholder%
              }
            }
          }
        }
      }`

    const sideCardNode = await puxGraphqlHelper<any>(
        sideCardQuery.replace(/%widgetFragmentsPlaceholder%/g, ``),
        {
            errorMetadata: {
                fromFunction: `Error while running GraphQL query for sideCardNode in getSideCardData.ts`,
                metadata: `
                    line: 26, 
                    termContentItemId: "${nodeTermContentItemId}",
                    nodeTermContentItemId: "${nodeTermContentItemId}",
                    query: "${taxonomyQuery}"`
            }
        }
    )

    if (sideCardNode.errors) {
        return sideCardWidgets
    }

    if (!sideCardNode || !sideCardNode.easySoftwareSideCard[0]) {
        return sideCardWidgets
    }

    await Promise.all(
        sideCardNode.easySoftwareSideCard[0].widgetZone.widgets.map(
            async (widget) => {
                return new Promise(async (resolve) => {
                    const sideCardWidgetQuery = sideCardQuery
                        .replace(`contentType`, ``)
                        .replace(
                            /%widgetFragmentsPlaceholder%/g,
                            getWidgetQuery(widget.contentType, preferedContentLinkCulture)
                        )

                    const sideCardWidgetNode = await puxGraphqlHelper<any>(
                        sideCardWidgetQuery,
                        {
                            errorMetadata: {
                                fromFunction: `Error while running GraphQL query for sideCardWidgetNode in getSideCardData.ts`,
                                metadata: `
                                    query: "${sideCardWidgetQuery}"`
                            }
                        }
                    )

                    if (sideCardWidgetNode.errors) {
                        return
                    }

                    if (
                        sideCardWidgetNode &&
                        sideCardWidgetNode.easySoftwareSideCard
                    ) {
                        // todo: do just one request per contenttype
                        sideCardWidgetNode.easySoftwareSideCard[0].widgetZone.widgets
                            .filter((responseWidget) => {
                                return (
                                    Object.keys(responseWidget).length > 0 &&
                                    !sideCardWidgets.some(
                                        (x) => x.contentItemId == responseWidget.contentItemId
                                    ) &&
                                    responseWidget.contentItemId == widget.contentItemId
                                )
                            })
                            .map((w) => {
                                resolve(w)
                            })
                    }
                })
            }
        )
    ).then((widgets) => {
        widgets.map((widget) => {
            sideCardWidgets.push(widget)
        })
    })

    return sideCardWidgets
}

export default getSideCardWidgets

export const getSpecificSideCardWidgets = async (
    preferedContentLinkCulture,
    contentItemId
) => {
    const { locale } = getBuilderService()

    const sideCardWidgets: any[] = []
    const sideCardQuery = `{
      orchard {
        easySoftwareSideCard (
          where: {localization: {culture: "${locale.isoCode}"}}
          ) {
            contentItemId
            sideCardContentLink {
              cultureContentItems(culture: "${preferedContentLinkCulture}") {
                contentItemId
              }
            }
            widgetZone {
              widgets {
                contentType
                contentItemId
              }
            }
          }
        }
      }`

    const sideCardNode = await puxGraphqlHelper<any>(
        sideCardQuery,
        {
            errorMetadata: {
                fromFunction: `Error while running GraphQL query for taxonomyNodes in getSideCardData.ts`,
                metadata: `
                    contentItemId: "${contentItemId}", 
                    query: "${sideCardQuery}"`
            }
        }
    )

    if (sideCardNode.errors) {
        return sideCardWidgets
    }

    if (!sideCardNode || !sideCardNode.easySoftwareSideCard[0]) {
        return sideCardWidgets
    }

    const filteredSideCardNode = sideCardNode.easySoftwareSideCard.filter(
        (sideCard) => {
            if (sideCard.sideCardContentLink && sideCard.sideCardContentLink.cultureContentItems.length > 0) {
                return sideCard.sideCardContentLink.cultureContentItems[0].contentItemId === contentItemId
            }
            return
        }
    )

    if (!filteredSideCardNode.length) {
        return sideCardWidgets
    }

    const specificSidecardContentItemId =
        filteredSideCardNode[0].contentItemId || undefined

    if (!specificSidecardContentItemId) {
        return sideCardWidgets
    }

    await Promise.all(
        filteredSideCardNode[0].widgetZone.widgets.map(async (widget) => {
            return new Promise(async (resolve) => {
                const sideCardWidgetsQuery = `{
                    orchard {
                        easySoftwareSideCard (
                        where: {contentItemId: "${specificSidecardContentItemId}"}
                        ) {
                            widgetZone {
                            widgets {
                                contentType
                                %widgetFragmentsPlaceholder%
                            }
                            }
                        }
                        }
                    }`
                    .replace(`contentType`, ``)
                    .replace(
                        /%widgetFragmentsPlaceholder%/g,
                        getWidgetQuery(widget.contentType, preferedContentLinkCulture)
                    )

                const sideCardWidgetNode = await puxGraphqlHelper<any>(
                    sideCardWidgetsQuery,
                    {
                        errorMetadata: {
                            fromFunction: `Error while running GraphQL query for sideCardWidgetNode in getSpecificSideCardWidgets() in getSideCardData.ts`,
                        }
                    }
                )

                if (sideCardWidgetNode.errors) {
                    return sideCardWidgets
                }

                if (
                    sideCardWidgetNode &&
                    sideCardWidgetNode.easySoftwareSideCard
                ) {
                    // todo: do just one request per contenttype
                    sideCardWidgetNode.easySoftwareSideCard[0].widgetZone.widgets
                        .filter((responseWidget) => {
                            return (
                                Object.keys(responseWidget).length > 0 &&
                                !sideCardWidgets.some(
                                    (x) => x.contentItemId == responseWidget.contentItemId
                                ) &&
                                responseWidget.contentItemId == widget.contentItemId
                            )
                        })
                        .map((w) => {
                            resolve(w)
                        })
                }
            })
        })
    ).then((widgets) => {
        widgets.map((widget) => {
            sideCardWidgets.push(widget)
        })
    })

    return sideCardWidgets
}

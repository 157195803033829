import React, { FunctionComponent, useEffect } from 'react'

import EasySoftwareLottie from '../content/mediaAnimation/EasySoftwareLottie'
import EasySoftwareBackgroundVideo from '../content/mediaBackgroundVideo/EasySoftwareBackgroundVideo'
import PuxMediaSelector, { PuxMediaSelectorType } from '../content/mediaImage/PuxMediaSelector'
import PuxButton, { PuxButtonType } from '../PuxButton/PuxButton'
import PuxVideo, { PuxVideoType } from '../PuxVideo/PuxVideo'
import { MediaSelectorImageType } from '../content/mediaImage/PuxPicture'
import EasySoftwareEditorContainer from '../EasySoftwareEditorContainer/EasySoftwareEditorContainer'

export interface EasySoftwareTabsItemType {
  contentType?: string,
  contentItemId?: string,
  displayText: string,
  tabsItemContent: {
    html: string
  }
  puxButton: PuxButtonType,
  tabsItemMedia: {
    cultureContentItems: [{
      displayText
      contentType
      backgroundVideoFileMP4: {
        paths: [string],
        type: [string]
      },
      backgroundVideoFileWEBM: {
        paths: [string],
        type: [string]
      },
      backgroundVideoPlaceholder: {
        paths: [string]
      },
      lottieJSON: string,
      lottieJSONMobile: string | null,
      lottieImage: MediaSelectorImageType | null,
      backgroundVideoLoop: boolean,
      backgroundVideoAutoplay: boolean,
      backgroundVideoControls: boolean,
      backgroundVideoShowOnMobile: boolean,
      puxVideo: PuxVideoType,
    }]
  },
  puxMediaSelector: PuxMediaSelectorType
}

export interface EasySoftwareTabsItemProps {
  data: EasySoftwareTabsItemType,
  index: number,
  isOpen: boolean,
  setTabsOpen: (index) => void
  pageContentItemId: string,
  editorPath: string,
}

const EasySoftwareTabsItem: FunctionComponent<EasySoftwareTabsItemProps> = (
  props
) => {
  const media = props.data?.tabsItemMedia?.cultureContentItems?.length > 0 ? props.data.tabsItemMedia.cultureContentItems[0] : null
  const image = props.data.puxMediaSelector

  function returnMedia() {
    if (media === null) {
      return ""
    }

    switch (media.contentType) {
      case `EasySoftwareLottie`:
        return (
          <EasySoftwareLottie
            lottieImage={media.lottieImage}
            animationData={JSON.parse(media.lottieJSON)}
            animationDataMobile={media.lottieJSONMobile ? JSON.parse(media.lottieJSONMobile) : null}
          />
        );
      case `EasySoftwareHeroBannerBackground`:
        return (
          <PuxMediaSelector
            {...media.heroBannerBackgroundImage}
            width={800}
            height={470}
            disableLazyload={true}
          />
        );
      case `EasySoftwareBackgroundVideo`:
        return (
          <EasySoftwareBackgroundVideo
            {...media}
          />
        );
      case `PuxVideo`:
        return (
          <PuxVideo
            videoStructuredDataContentUrl={
              media.puxVideo.videoStructuredDataContentUrl
            }
            videoStructuredDataDescription={
              media.puxVideo.videoStructuredDataDescription
            }
            videoStructuredDataName={
              media.puxVideo.videoStructuredDataName
            }
            videoStructuredDataUploadDate={
              media.puxVideo.videoStructuredDataUploadDate
            }
            videoThumbnail={media.puxVideo.videoThumbnail}
            videoYTID={media.puxVideo.videoYTID}
          />
        );

      default:
        return ``;
    }
  }

  function returnImage() {
    return (
      <PuxMediaSelector
        {...image}
        width={720}
        height={400}
      />
    )
  }

  useEffect(() => {
    if (props.isOpen) {
      const video: HTMLVideoElement | null = document.querySelector(`.Tabs-item.active video`) ?? null;
      if (video) {
        video.pause();
        video.currentTime = 0;
        video.load();
      }
    }
  }, [props.isOpen])

  return (
    <div className={`Tabs-item ${props.isOpen ? `active` : ``}`}>
      <div className="Tabs-content">
        <div>
          <EasySoftwareEditorContainer pageContentItemId={props.pageContentItemId} className="Tabs-text" content={props.data.tabsItemContent} wysiwygId={props.data.contentItemId} editorPath={props.editorPath} />
          <PuxButton
            buttonType={props.data.puxButton.buttonType}
            buttonSize={props.data.puxButton.buttonSize}
            buttonColor={props.data.puxButton.buttonColor}
            buttonLink={{
              url: props.data.puxButton.buttonLink.url,
              internal: props.data.puxButton.buttonLink.internal,
              text: props.data.puxButton.buttonLink.text,
            }}
          />
        </div>
        <div className="Tabs-media">
          <div className="Tabs-media-container">
            {media && returnMedia()}
            {!media && image ? returnImage() : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EasySoftwareTabsItem

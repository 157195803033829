import { AnchorItemType } from "Root/EasySoftwareGatsby/src/components/core/AnchorsNav"
import { localizedPathType } from "../components/PuxLanguageSelector/PuxLanguageSelector"
import { PuxVideoType } from "../components/PuxVideo/PuxVideo"
import { PuxWysiwygHtmlBodyType } from "../components/PuxWysiwyg/PuxWysiwyg"
import { PuxMediaSelectorType } from "../components/content/mediaImage/PuxMediaSelector"
import { ContentPickerType } from "../templates/Pricing/PlansTable"
import { PricingCurrencyType, PricingFeatureCategoryType, PricingFeatureType } from "../templates/Pricing/PricingPage"
import { PuxMetaTagsType } from "../utils/PuxMetaTags"
import { IPageContext } from "../utils/data/buildTypedPage"
import { PageProps } from 'gatsby'
import { LinkItemType } from "../utils/menuItemsMapper"
import { LinkType } from "../types/Link"

export const getPageQuery = (
  contentType: PageContentType,
  pagePath?: string,
  isPreview: boolean = false,
  preferedContentLinkCulture = ""
) => {
  switch (contentType) {
    case `PuxHomePage`:
      return `
        query Homepage {
          orchard {
            puxHomePage(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
              localization {
                localizations {
                  ... on Orchard_PuxHomePage {
                    path
                    published
                    localization {
                      culture
                    }
                  }
                }
              }
            }
          }
        }
      `

    case `EasySoftwareNews`:
      return `
        query News {
        orchard {
            easySoftwareNews(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
            contentType
            contentItemId
            path
            localization {
                localizations {
                ... on Orchard_EasySoftwareNews {
                    path
                    published
                    localization {
                    culture
                    }
                }
                }
            }
            displayText
            createdUtc
            modifiedUtc
            newsAuthor
            newsPerex {
                html
            }
            newsContent {
                html
            }
            newsImage {
                mediaSelectorImage {
                resizePaths(width: 1920, mode: "Min", quality: 75)
                resizePathsMobile(width: 768, mode: "Min", quality: 75)
                resizePathsTablet(width: 1200, mode: "Min", quality: 75)
                }
            }
            puxVideo {
                videoStructuredDataContentUrl
                videoStructuredDataDescription
                videoStructuredDataName
                videoStructuredDataUploadDate
                videoThumbnail {
                resizePaths(width: 1920, mode: "Min", quality: 75)
                resizePathsMobile(width: 768, mode: "Min", quality: 75)
                resizePathsTablet(width: 1200, mode: "Min", quality: 75)
                }
                videoYTID {
                html
                }
            }
          }
        }
      }
    `

    case `EasySoftwareArticle`:
      return `
        query Article {
          orchard {
            easySoftwareArticle(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
              contentType
              contentItemId
              path
              displayText
              localization {
                localizations {
                  ... on Orchard_EasySoftwareArticle {
                    path
                    published
                    localization {
                      culture
                    }
                  }
                }
              }
              articlePerex {
                html
              }
              articleContent {
                html
              }
            }
          }
        }
      `

    case `EasySoftwarePage`:
      return `
      query Page {
        orchard {
          easySoftwarePage(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
            displayText
            localization {
              localizations {
                ... on Orchard_EasySoftwarePage {
                  path
                  published
                  localization {
                    culture
                  }
                }
              }
            }
          }
        }
      }
    `

    case `EasySoftwarePage`:
      return `
      query Page {
        orchard {
          easySoftwarePage(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
            displayText
            localization {
              localizations {
                ... on Orchard_EasySoftwarePage {
                  path
                  published
                  localization {
                    culture
                  }
                }
              }
            }
          }
        }
      }
    `

    case `EasySoftwareCaseStudy`:
      return `
      query CaseStudy {
        orchard {
          easySoftwareCaseStudy(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
            contentType
            contentItemId
            path
            displayText
            localization {
              localizations {
                ... on Orchard_EasySoftwareCaseStudy {
                  path
                  published
                  localization {
                    culture
                  }
                }
              }
            }
            caseStudyUrl
            caseStudyPerex {
              html
            }
            caseStudyContent {
              html
            }
            caseStudyTopImage {
              mediaSelectorImage {
                resizePaths(width: 1920, mode: "Min", quality: 75)
                resizePathsMobile(width: 768, mode: "Min", quality: 75)
                resizePathsTablet(width: 1200, mode: "Min", quality: 75)
              }
            }
            caseStudyImage {
              mediaSelectorImage {
                resizePaths(width: 1920, mode: "Min", quality: 75)
                resizePathsMobile(width: 768, mode: "Min", quality: 75)
                resizePathsTablet(width: 1200, mode: "Min", quality: 75)
              }
            }
            caseStudyParameters {
              contentItems {
                ... on Orchard_PuxKeyValue {
                  displayText
                  contentItemId
                  keyValueContent {
                    html
                  }
                }
              }
            }
          }
        }
      }
    `

    case `EasySoftwareFunction`:
      return `
        query Function {
          orchard {
            easySoftwareFunction(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
              contentType
              path
              contentItemId
              displayText
              localization {
                localizations {
                  ... on Orchard_EasySoftwareFunction {
                    path
                    published
                    localization {
                      culture
                    }
                  }
                }
              }
              functionPerex {
                html
              }
              functionContent {
                html
              }
              functionImage {
                mediaSelectorImage {
                  resizePaths(width: 1920, mode: "Min", quality: 75)
                  resizePathsMobile(width: 768, mode: "Min", quality: 75)
                  resizePathsTablet(width: 1200, mode: "Min", quality: 75)
                  texts
                }
              }
              functionGallery {
                mediaSelectorImage {
                  resizePaths(width: 1920, mode: "Min", quality: 75)
                  resizePathsMobile(width: 768, mode: "Min", quality: 75)
                  resizePathsTablet(width: 1200, mode: "Min", quality: 75)
                }
              }
              puxVideo {
                videoStructuredDataContentUrl
                videoStructuredDataDescription
                videoStructuredDataName
                videoStructuredDataUploadDate
                videoYTID {
                  html
                }
              }
            }
          }
        }
      `

    case `EasySoftwareCategoryPage`:
      return `
        query Category {
          orchard {
            easySoftwareCategoryPage(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
              contentType
              path
              contentItemId
              displayText
              localization {
                localizations {
                  ... on Orchard_EasySoftwareCategoryPage {
                    path
                    published
                    localization {
                      culture
                    }
                  }
                }
              }
              categoryPageDescription {
                html
              }
              categoryPageTitleAlign
            }
          }
        }
      `

    case `EasySoftwareChangeLog`:
      return `
        query ChangeLog {
          orchard {
            easySoftwareChangeLog(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
              contentType
              path
              contentItemId
              displayText
              localization {
                localizations {
                  ... on Orchard_EasySoftwareChangeLog {
                    path
                    published
                    localization {
                      culture
                    }
                  }
                }
              }
            }
          }
        }
      `

    case `EasySoftwareFaq`:
      return `
        query Faq {
          orchard {
            easySoftwareFaq(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
              contentType
              path
              contentItemId
              displayText
              localization {
                localizations {
                  ... on Orchard_EasySoftwareFaq {
                    path
                    published
                    localization {
                      culture
                    }
                  }
                }
              }
              faqAnswer {
                html
              }
            }
          }
        }
      `

    case `EasySoftwarePricingPage`:
      return `
      query PricingPage {
        orchard {
          easySoftwarePricingPage(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
            contentType
            contentItemId
            path
            displayText
            localization {
              localizations {
                ... on Orchard_EasySoftwarePricingPage {
                  path
                  published
                  localization {
                    culture
                  }
                }
              }
            }
            pricingPagePerex {
              html
            }
            pricingPageEnterpriseContent {
              html
            }
            pricingPageCurrencies {
              contentItems {
                ... on Orchard_EasySoftwarePricingCurrency {
                  contentType
                  contentItemId
                  displayText
                  pricingCurrencySymbol
                  pricingCurrencyPriceEssential
                  pricingCurrencyPriceBusiness
                  pricingCurrencyPricePlatform
                  pricingCurrencyDefaultInCountriesList
                }
              }
            }
            pricingPageFeatures {
              contentItems {
                ... on Orchard_EasySoftwarePricingFeature {
                  contentType
                  contentItemId
                  displayText
                  pricingFeatureTooltip
                  pricingFeaturePlanPlatform
                  pricingFeaturePlanEssential
                  pricingFeaturePlanBusiness
                  pricingFeaturePlanEssentialInput {
                    html
                  }
                  pricingFeaturePlanBusinessInput {
                    html
                  }
                  pricingFeaturePlanPlatformInput {
                    html
                  }
                  pricingFeaturePlanEnterpriseInput {
                    html
                  }
                }
              }
            }
            pricingPageAllFeatures {
              contentItems {
                ... on Orchard_EasySoftwarePricingFeatureCategory {
                  displayText
                  contentItemId
                  contentType
                  pricingFeatureCategoryItems {
                    contentItems {
                      ... on Orchard_EasySoftwarePricingFeature {
                        displayText
                        contentItemId
                        contentType
                        pricingFeaturePlanBusiness
                        pricingFeaturePlanEnterprise
                        pricingFeaturePlanEssential
                        pricingFeaturePlanPlatform
                        pricingFeatureTooltip
                        pricingFeaturePlanEssentialInput {
                          html
                        }
                        pricingFeaturePlanBusinessInput {
                          html
                        }
                        pricingFeaturePlanPlatformInput {
                          html
                        }
                        pricingFeaturePlanEnterpriseInput {
                          html
                        }
                      }
                    }
                  }
                }
              }
            }
            pricingPageBuyLink {
              internal
              text
              url(culture: "${preferedContentLinkCulture}")
            }
          }
        }
      }
    `

    case `PuxLandingPage`:
      return `
        query LandingPage {
          orchard {
            puxLandingPage(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
              contentType
              contentItemId
              path
              displayText
              landingPageButton {
                text
                url
              }
              landingPageAnchors {
                contentItems {
                  ... on Orchard_EasySoftwareAnchorItem {
                    displayText
                    contentType
                    anchorItemTarget
                  }
                  ... on Orchard_EasySoftwareLinkItem {
                    contentType
                    linkItemTarget {
                      internal
                      text
                      url(culture: "${preferedContentLinkCulture}")
                    }
                  }
                }
              }
              easySoftwareOptimizeSettings {
                optimizeSettingsAntiFlicker
                optimizeSettingsContainer
              }
              localization {
                localizations {
                  ... on Orchard_PuxLandingPage {
                    path
                    published
                    localization {
                      culture
                    }
                  }
                }
              }
            }
          }
        }
      `

    case `EasySoftwareTrainingCenter`:
      return `
        query TrainingCenter {
          orchard {
            easySoftwareTrainingCenter(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
              contentType
              path
              contentItemId
              displayText
              localization {
                localizations {
                  ... on Orchard_EasySoftwareTrainingCenter {
                    path
                    published
                    localization {
                      culture
                    }
                  }
                }
              }
            }
          }
        }
      `

    case `EasySoftwareKnowledgeBase`:
      return `
        query KnowledgeBase {
          orchard {
            easySoftwareKnowledgeBase(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
              displayText
              localization {
                localizations {
                  ... on Orchard_EasySoftwareKnowledgeBase {
                    path
                    published
                    localization {
                      culture
                    }
                  }
                }
              }
            }
          }
        }
      `

    case `EasySoftwareKnowledgeBaseCategory`:
      return `
        query KnowledgeBaseCategory {
          orchard {
            easySoftwareKnowledgeBaseCategory(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
              displayText
              localization {
                localizations {
                  ... on Orchard_EasySoftwareKnowledgeBaseCategory {
                    path
                    published
                    localization {
                      culture
                    }
                  }
                }
              }
            }
          }
        }
      `

    case `EasySoftwareKnowledgeBaseArticle`:
      return `
        query KnowledgeBaseArticle {
          orchard {
            easySoftwareKnowledgeBaseArticle(first: 1,${isPreview ? " status: ALL," : ""} where: { path: "${pagePath ?? ""}" }) {
              displayText
              contentItemId
              knowledgeBaseArticleContent {
                html
              }
              knowledgeBaseArticleTags {
                termContentItems {
                  displayText
                }
              }
              localization {
                localizations {
                  ... on Orchard_EasySoftwareKnowledgeBaseArticle {
                    path
                    published
                    localization {
                      culture
                    }
                  }
                }
              }
            }
          }
        }
      `

    default:
      return ``
  }
}

export type PageContentType =
  "PuxHomePage" |
  "EasySoftwareNews" |
  "EasySoftwareArticle" |
  "EasySoftwarePage" |
  "EasySoftwareCaseStudy" |
  "EasySoftwareFunction" |
  "EasySoftwareCategoryPage" |
  "EasySoftwareChangeLog" |
  "EasySoftwareFaq" |
  "EasySoftwarePricingPage" |
  "PuxLandingPage" |
  "EasySoftwareTrainingCenter" |
  "EasySoftwareKnowledgeBase" |
  "EasySoftwareKnowledgeBaseCategory" |
  "EasySoftwareKnowledgeBaseArticle"

//TODO: better naming plz
export type PageType =
  IEasySoftwareNewsPage |
  IEasySoftwareArticlePage |
  IEasySoftwarePage |
  IEasySoftwareCaseStudy |
  IEasySoftwareFunction |
  IEasySoftwareCategoryPage |
  IEasySoftwareChangeLog |
  IEasySoftwareFaq |
  IEasySoftwarePricingPage |
  IPuxLandingPage |
  IEasySoftwareTrainingCenter |
  IEasySoftwareKnowledgeBase |
  IEasySoftwareKnowledgeBaseCategory |
  IEasySoftwareKnowledgeBaseArticle


export interface BasePage {
  contentType: string
  contentItemId: string
  contentItemVersionId?: string
  displayText: string
  path: string
  localization: {
    localizations: localizedPathType[]
  }
  puxMetaTags: PuxMetaTagsType
  puxCategory: {
    taxonomyContentItemId: string
    termContentItemIds: string[]
  }
}

export interface IEasySoftwareNewsPage extends BasePage {
  contentType: "EasySoftwareNews"
  createdUtc: string
  modifiedUtc: string
  newsAuthor: string
  newsPerex: PuxWysiwygHtmlBodyType
  newsContent: PuxWysiwygHtmlBodyType
  newsImage: PuxMediaSelectorType
  puxVideo: PuxVideoType
}

export interface IEasySoftwareArticlePage extends BasePage {
  contentType: "EasySoftwareArticle"
  articlePerex: PuxWysiwygHtmlBodyType
  articleContent: PuxWysiwygHtmlBodyType
}

export interface IEasySoftwarePage extends BasePage {
  contentType: "EasySoftwarePage"
}

export interface IEasySoftwareCaseStudy extends BasePage {
  contentType: "EasySoftwareCaseStudy"
  caseStudyUrl: string
  caseStudyPerex: PuxWysiwygHtmlBodyType
  caseStudyContent: PuxWysiwygHtmlBodyType
  caseStudyImage: PuxMediaSelectorType
  caseStudyTopImage?: PuxMediaSelectorType
  caseStudyParameters: {
    contentItems: [PuxKeyValueType]
  }
}

export interface IEasySoftwareFunction extends BasePage {
  contentType: "EasySoftwareFunction"
  functionPerex: PuxWysiwygHtmlBodyType
  functionContent: PuxWysiwygHtmlBodyType
  functionImage: PuxMediaSelectorType
  functionGallery: PuxMediaSelectorType
  puxVideo: PuxVideoType
}

export interface IEasySoftwareCategoryPage extends BasePage {
  contentType: "EasySoftwareCategoryPage"
  categoryPageDescription: PuxWysiwygHtmlBodyType
  categoryPageTitleAlign: '' | 'u-textCenter'
}

export interface IEasySoftwareChangeLog extends BasePage {
  contentType: "EasySoftwareChangeLog"
}

export interface IEasySoftwareFaq extends BasePage {
  contentType: "EasySoftwareFaq"
  faqAnswer: PuxWysiwygHtmlBodyType
}

export interface IEasySoftwarePricingPage extends BasePage {
  contentType: "EasySoftwarePricingPage"
  pricingPagePerex: PuxWysiwygHtmlBodyType
  pricingPageEnterpriseContent: PuxWysiwygHtmlBodyType
  pricingPageCurrencies: {
    contentItems: [PricingCurrencyType]
  }
  pricingPageFeatures: {
    contentItems: [PricingFeatureType]
  }
  pricingPageAllFeatures: {
    contentItems: [PricingFeatureCategoryType]
  }
  pricingPageBuyLink: ContentPickerType
}

export interface IPuxLandingPage extends BasePage {
  contentType: "PuxLandingPage"
  landingPageButton: LinkType
  landingPageAnchors: {
    contentItems: (AnchorItemType | LinkItemType)[]
  }
  easySoftwareOptimizeSettings?: {
    optimizeSettingsAntiFlicker?: boolean
    optimizeSettingsContainer?: string
  }
}

export interface IEasySoftwareTrainingCenter extends BasePage {
  contentType: "EasySoftwareTrainingCenter"
}

export interface IEasySoftwareKnowledgeBase extends BasePage {
  contentType: "EasySoftwareKnowledgeBase"
}

export interface IEasySoftwareKnowledgeBaseCategory extends BasePage {
  contentType: "EasySoftwareKnowledgeBaseCategory"
}

export interface IEasySoftwareKnowledgeBaseArticle extends BasePage {
  contentType: "EasySoftwareKnowledgeBaseArticle"
  knowledgeBaseArticleContent: {
    html: string
  }
  knowledgeBaseArticleTags: {
    taxonomyContentItemId: string
    termContentItemIds: string[]
    termContentItems: {
      displayText: string
    }[]
  }
}

export interface GenericPageProps<T> extends PageProps {
  pageContext: IPageContext<T>
}

export interface PuxKeyValueType {
  contentItemId: string
  displayText: string
  keyValueContent: PuxWysiwygHtmlBodyType
}
import './EasySoftwareSearch.scss'

import axios from 'axios'
import { concat, debounce } from 'lodash'
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { searchConfig } from 'Root/puxconfig'
import { t } from 'ttag'

import EasySoftwareSearchResult, {
  EasySoftwareSearchResultItem,
} from './EasySoftwareSearchResult'

interface EasySoftwareSearchResponse {
  status: number
  data: {
    items: [EasySoftwareSearchResultItem]
    searchInformation: {
      totalResults: number
      formattedTotalResults: string
      formattedSearchTime: string
    }
  }
}

interface EasySoftwareSearchProps {
  searchType?: 'header' | 'headerRespo' | 'knowledgebase'
}

const LOCALE = process.env.LOCALE ? process.env.LOCALE : `en`
const PROJECT = process.env.PROJECT ? process.env.PROJECT : `EasyRedmineGatsby`

const EasySoftwareSearch: FunctionComponent<EasySoftwareSearchProps> = (
  props
) => {
  const [searchQuery, setSearchQuery] = useState(``)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<EasySoftwareSearchResultItem[]>([])
  const [showLoadMore, setShowLoadMore] = useState(false)
  const [resultsIndex, setResultsIndex] = useState(0)
  const [total, setTotal] = useState(0)
  const delayedQuery = useCallback(
    debounce((query) => getSearchData(query), 500),
    []
  )
  const searchWrapper = useRef<HTMLDivElement>(null)
  const searchType = props.searchType ?? `header`
  let searchApiURL: string

  switch (searchType) {
    case `knowledgebase`:
      searchApiURL = `${searchConfig[PROJECT].apiUrl}?key=${searchConfig[PROJECT].apiKey}&cx=${searchConfig[PROJECT].localizedOptions[LOCALE].index}&siteSearch=${searchConfig[PROJECT].localizedOptions[LOCALE].knowledgeBaseUrl}&siteSearchFilter=i&q=`
      break
    default:
      searchApiURL = `${searchConfig[PROJECT].apiUrl}?key=${searchConfig[PROJECT].apiKey}&cx=${searchConfig[PROJECT].localizedOptions[LOCALE].index}&q=`
      break
  }

  const getSearchData = (query: string) => {
    axios
      .get(`${searchApiURL}${query}`)
      .then((response: EasySoftwareSearchResponse) => {
        if (response.status === 200) {
          setData(response.data.items)
          setTotal(response.data.searchInformation.totalResults)
          setShowLoadMore(
            isThereMoreToLoad(response.data.searchInformation.totalResults)
          )
        }
        setLoading(false)
      })
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
    if (e.target.value.length > 2) {
      setLoading(true)
      delayedQuery(e.target.value)
    } else if (e.target.value.length === 0) {
      reset()
    }
  }

  const handleLoadMore = () => {
    setLoading(true)
    setShowLoadMore(true)
    setResultsIndex(resultsIndex + 10)
    axios
      .get(`${searchApiURL}${searchQuery}&start=${resultsIndex}`)
      .then((response: EasySoftwareSearchResponse) => {
        if (response.status === 200) {
          setData(concat(data, response.data.items))
          setShowLoadMore(isThereMoreToLoad())
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        setShowLoadMore(isThereMoreToLoad())
      })
  }

  const isThereMoreToLoad = (totalCount?: number): boolean => {
    totalCount = totalCount ? totalCount : total
    return totalCount - Math.max(resultsIndex, 1) * 10 > 0
  }

  const reset = () => {
    setData([])
    setShowLoadMore(false)
    setTotal(0)
  }

  useEffect(() => {
    document.addEventListener(`click`, (e) => {
      if (
        searchWrapper.current &&
        !searchWrapper.current.contains(e.target as Node)
      ) {
        if (searchWrapper.current.classList.contains(`is-opened`)) {
          searchWrapper.current.classList.remove(`is-opened`)
        }
      }
    })
  }, [])

  const searchClass = 'Search--' + searchType.charAt(0).toLowerCase() + searchType.slice(1);

  return (
    <div
      className={`Search ${searchClass}`}
      ref={searchWrapper}
    >
      {searchType === `header` && (
        <div className={`Search-icon`}
          onClick={() => {
            const thisWrapper = searchWrapper.current

            if (!thisWrapper) return

            if (thisWrapper.classList.contains(`is-opened`)) {
              thisWrapper.classList.remove(`is-opened`)
            } else {
              thisWrapper.classList.add(`is-opened`)
              setTimeout(() => {
                thisWrapper
                  .querySelector<HTMLInputElement>(`.Search-input`)
                  ?.focus()
              }, 300)
            }
          }}
        >
        </div >
      )}
      <div className={`Search-wrapper${loading ? ` loading` : ``}`}>

        <input
          className={`Search-input`}
          type='text'
          value={searchQuery}
          placeholder={
            (searchType === `header` || searchType === `headerRespo`)
              ? t`Redmine.Search.Placeholder`
              : t`Redmine.KnowledgeBase.Search.Placeholder`
          }
          onChange={(e) => handleSearch(e)}
        />
        <div className={`Search-output${data?.length ? ` Search-output--has-data` : ``}`}>
          {
            <div className={`Search-output-content`}>
              {total && total > 0 ? (
                <div className={`Search-results-count`}>
                  <strong>{total}</strong> {t`Redmine.Search.ResultsFound`}
                </div>
              ) : null}
              <ul>
                {data?.map((item, index) => (
                  <EasySoftwareSearchResult
                    {...item}
                    searchResultType={searchType}
                    key={index}
                  />
                ))}
              </ul>
              {showLoadMore ? (
                <div className={`Search-load-more`}>
                  <span
                    className={
                      searchType === `header`
                        ? `Button Button--primary Button--small Button--blue`
                        : `Button Button--primary Button--small Button--yellow`
                    }
                    onClick={() => handleLoadMore()}
                  >{t`Redmine.Search.LoadMore`}</span>
                </div>
              ) : null}
              {loading ? <div className='loader'></div> : null}
            </div>
          }
        </div>
      </div>
    </div >
  )
}

export default EasySoftwareSearch

import axios from 'axios'
import { getBuilderService } from "../data/puxBuilderService"
import { puxGraphqlHelper } from '../data/puxGraphqlHelper'
import { PageType } from 'Shared/queries/page-queries'
import { IFooterData, IHeaderData, getFooterQuery, getHeaderQuery } from 'Shared/queries/layout-queries'
import { getBaseQuery } from 'Shared/queries/base-queries'
import { getTypedData } from '../data/getTypedData'
import { IBuildTypedPages } from '../data/buildTypedPage'

export async function getTemplateDataFromUrl(url: string): Promise<ITemplateData> {
    const query = `{
        pathItem(path: "${url}", anyStatus: true) {
            contentItemId
            contentItemVersionId
            contentType
            culture
            path
        }
    }`

    const response = await puxGraphqlHelper<ITemplateQuery>(query)

    return {
        ...response.pathItem
    }
}

export async function getAuthorizationToken() {
    const response = await axios
        .get(`${process.env.GATSBY_ORCHARD_API_URL}/puxapi/preview/token`, {
            withCredentials: true,
        })
        .then((response) => response.data)
        .catch((error) => error)

    getBuilderService().authorization = { ...response.token }
}

export async function fakeGraphql<TData, TVariables = TokenData>(query: string, variables?: TVariables): Promise<{ errors?: any; data?: TData | undefined }> {
    let token_type = getBuilderService().authorization.token_type
    let access_token = getBuilderService().authorization.access_token

    const finalQuery = query.replace(/\n/g, ``).includes('query ') ? query.replace(/\n/g, ``) : `query ${query}`.replace(/\n/g, ``)

    return await axios
        .post<TData>(
            `${process.env.GATSBY_API_URL}`,
            finalQuery,
            {
                headers: {
                    'Content-Type': `application/graphql`,
                    'Access-Control-Allow-Origin': `*`,
                    Authorization: `${token_type} ${access_token}`,
                },
            }
        ).then(response => {
            return response.data as { errors?: any; data?: TData | undefined }
        }).catch(error => {
            return {
                errors: error
            }
        })
}

export const getTypedPageData = async (templateData: ITemplateData, { layout, template, addons }: IBuildTypedPages) => {
    const baseQuery = getBaseQuery({
        contentType: template.contentType,
        sections: template.widgetZones,
        addPuxCategoryToQuery: addons.addBreadCrumbs || addons.addSideCards,
        addMetaTags: addons.addMetaTags,
        customBreadCrumbsTax: addons.customBreadCrumbsTax
    })

    // change first char in string to lowercase
    const contentTypeCamelCase = template.contentType.charAt(0).toLowerCase() + template.contentType.slice(1)

    const query = baseQuery
        .replace(
            /%baseSectionQueryContentType%/g,
            `
                contentType
                contentItemId
            `
        )
        .replace(/%widgetFragmentsPlaceholder%/g, ``)
        .replace(
            /%pathCondition%/g,
            `(where: {contentItemId: "${templateData.contentItemId}", contentItemVersionId: "${templateData.contentItemVersionId}"}, status: ALL)`
        )

    const result = await puxGraphqlHelper<PageType>(
        query,
        {
            errorMetadata: {
                fromFunction: `Error while running GraphQL query for ${template.contentType} in gatsby-node.ts`,
            }
        }
    )

    const resultContentType = result[contentTypeCamelCase].find((o) => o.path)

    const page = await getTypedData(resultContentType, { layout, template, addons }, true)

    if (page) {
        return page
    }
}


export const getEditData = async (templateData: ITemplateData) => {
    let buildData: IBuildTypedPages | undefined
    const isoCode = getBuilderService().locale.isoCode

    const headerData = await puxGraphqlHelper<IHeaderData>(getHeaderQuery(isoCode), { errorMetadata: { fromFunction: "buildHeader() in getEditData.ts" } })
    const footerData = await puxGraphqlHelper<IFooterData>(getFooterQuery(isoCode), { errorMetadata: { fromFunction: "buildFooter() in getEditData.ts" } })

    switch (templateData.contentType) {
        case `EasySoftwareArticle`:
            buildData = {
                layout: {
                    headerData,
                    footerData
                },
                template: {
                    contentType: `EasySoftwareArticle`,
                    widgetZones: [`articleWidgets`],
                    templatePath: `../Shared/templates/Article/ArticlePage.tsx`
                },
                addons: {
                    addBreadCrumbs: true,
                    addMetaTags: true,
                    addSideCards: true,
                    customBreadCrumbsTax: undefined
                }
            }
            break;

        case `EasySoftwareCategoryPage`:
            buildData = {
                layout: {
                    headerData,
                    footerData
                },
                template: {
                    contentType: `EasySoftwareCategoryPage`,
                    widgetZones: [`categoryWidgets`],
                    templatePath: `../Shared/templates/Category/CategoryPage.tsx`
                },
                addons: {
                    addBreadCrumbs: false,
                    addMetaTags: true,
                    addSideCards: true,
                    customBreadCrumbsTax: undefined
                }
            }
            break;

        case `EasySoftwareCaseStudy`:
            buildData = {
                layout: {
                    headerData,
                    footerData
                },
                template: {
                    contentType: `EasySoftwareCaseStudy`,
                    widgetZones: [`caseStudyWidgets`],
                    templatePath: `../Shared/templates/CaseStudy/CaseStudyDetail.tsx`
                },
                addons: {
                    addBreadCrumbs: false,
                    addMetaTags: true,
                    addSideCards: true,
                    customBreadCrumbsTax: undefined
                }
            }
            break;

        case `EasySoftwareFaq`:
            buildData = {
                layout: {
                    headerData,
                    footerData
                },
                template: {
                    contentType: `EasySoftwareFaq`,
                    widgetZones: undefined,
                    templatePath: `../Shared/templates/Faq/FaqDetail.tsx`
                },
                addons: {
                    addBreadCrumbs: true,
                    addMetaTags: true,
                    addSideCards: true,
                    customBreadCrumbsTax: undefined
                }
            }
            break;

        case `EasySoftwareFunction`:
            buildData = {
                layout: {
                    headerData,
                    footerData
                },
                template: {
                    contentType: `EasySoftwareFunction`,
                    widgetZones: [`widgetZone`],
                    templatePath: `../Shared/templates/Function/FunctionDetail.tsx`
                },
                addons: {
                    addBreadCrumbs: true,
                    addMetaTags: true,
                    addSideCards: true,
                    customBreadCrumbsTax: undefined
                }
            }
            break;

        case `EasySoftwareKnowledgeBase`:
            buildData = {
                layout: {
                    headerData,
                    footerData
                },
                template: {
                    contentType: `EasySoftwareKnowledgeBase`,
                    widgetZones: undefined,
                    templatePath: `../Shared/templates/KnowledgeBase/KnowledgeBase.tsx`
                },
                addons: {
                    addBreadCrumbs: true,
                    addMetaTags: true,
                    addSideCards: true,
                    customBreadCrumbsTax: undefined
                }
            }
            break;

        case `EasySoftwareNews`:
            buildData = {
                layout: {
                    headerData,
                    footerData
                },
                template: {
                    contentType: `EasySoftwareNews`,
                    widgetZones: [`widgetZone`],
                    templatePath: `../Shared/templates/News/NewsDetail.tsx`
                },
                addons: {
                    addBreadCrumbs: true,
                    addMetaTags: true,
                    addSideCards: true,
                    customBreadCrumbsTax: undefined
                }
            }
            break;

        case `EasySoftwarePage`:
            buildData = {
                layout: {
                    headerData,
                    footerData
                },
                template: {
                    contentType: `EasySoftwarePage`,
                    widgetZones: [`widgetZone`],
                    templatePath: `../Shared/templates/easySoftwarePage.tsx`
                },
                addons: {
                    addBreadCrumbs: false,
                    addMetaTags: true,
                    addSideCards: true,
                    customBreadCrumbsTax: undefined
                }
            }
            break;

        case `EasySoftwarePricingPage`:
            buildData = {
                layout: {
                    headerData,
                    footerData
                },
                template: {
                    contentType: `EasySoftwarePricingPage`,
                    widgetZones: [`midWidgetZone`, `bottomWidgetZone`],
                    templatePath: `../Shared/templates/Pricing/PricingPage.tsx`
                },
                addons: {
                    addBreadCrumbs: false,
                    addMetaTags: true,
                    addSideCards: false,
                    customBreadCrumbsTax: undefined
                }
            }
            break;

        case `EasySoftwareTrainingCenter`:
            buildData = {
                layout: {
                    headerData,
                    footerData
                },
                template: {
                    contentType: `EasySoftwareTrainingCenter`,
                    widgetZones: [`topWidgetZone`],
                    templatePath: `../Shared/templates/TrainingCenter/TrainingCenterPage.tsx`
                },
                addons: {
                    addBreadCrumbs: false,
                    addMetaTags: true,
                    addSideCards: true,
                    customBreadCrumbsTax: undefined
                }
            }
            break;

        case `EasySoftwareChangeLog`:
            buildData = {
                layout: {
                    headerData,
                    footerData
                },
                template: {
                    contentType: `EasySoftwareChangeLog`,
                    widgetZones: undefined,
                    templatePath: `../Shared/templates/ChangeLog/ChangeLogPage.tsx`
                },
                addons: {
                    addBreadCrumbs: true,
                    addMetaTags: true,
                    addSideCards: true,
                    customBreadCrumbsTax: undefined
                }
            }
            break;

        case `PuxHomePage`:
            buildData = {
                layout: {
                    headerData,
                    footerData
                },
                template: {
                    contentType: `PuxHomePage`,
                    widgetZones: [`widgetZone`],
                    templatePath: `../Shared/templates/HomePage/PuxHomePage.tsx`
                },
                addons: {
                    addBreadCrumbs: false,
                    addMetaTags: true,
                    addSideCards: false,
                    customBreadCrumbsTax: undefined
                }
            }
            break;

        case `PuxLandingPage`:
            buildData = {
                layout: {
                    headerData,
                    footerData
                },
                template: {
                    contentType: `PuxLandingPage`,
                    widgetZones: [`landingWidgets`],
                    templatePath: `../Shared/templates/Landing/LandingPage.tsx`
                },
                addons: {
                    addBreadCrumbs: false,
                    addMetaTags: true,
                    addSideCards: false,
                    customBreadCrumbsTax: undefined
                }
            }
            break;

        default:
            buildData = undefined
            break;
    }

    if (!buildData) {
        throw new Error(`No template data found for ${templateData.contentType}`)
    }

    const pageData = await getTypedPageData(templateData, buildData)

    if (!pageData) {
        throw new Error(`No page data found for ${templateData.contentType}`)
    }

    return pageData
}

export const getFakePageProps = (location, context) => {
    return {
        "pageContext": context,
        "path": '',
        "uri": '',
        "location": { location },
        "navigate": () => new Promise<void>((resolve, reject) => { }),
        "children": { undefined },
        "params": { "": "" },
        "pageResources":
        {
            component: false,
            json: {
                data: {},
                pageContext: {}
            },
            page: {
                componentChunkName: '',
                path: '',
                webpackCompilationHash: '',
                matchPath: undefined
            }
        }
        ,
        "data": {},
        "serverData": {},
    } as any
}

export class fakeReporter {
    panicOnBuild(message: string) {
        console.error(message)
    }
}


interface TokenData {
    token_type: string
    access_token: string
}

interface ITemplateQuery {
    pathItem: ITemplateData
}

interface ITemplateData {
    contentItemId: string
    contentItemVersionId: string
    contentType: string
    path: string
}
import { useState, useEffect } from 'react'

export function usePreviewStatus() {
    const [isPreview, setIsPreview] = useState(false);
    useEffect(() => {
        if (typeof window !== "undefined") {
            if (window.location.pathname.includes("/preview/")) {
                setIsPreview(true);
                return;
            } else {
                setIsPreview(false);
                return;
            }
        }
    }, []);
    return isPreview;
}
import { langConfig, localeConfig } from "Root/puxconfig"


export function getPrefixedUrl(localizedUrl: string, prefixType: "edit" | "preview"): string {
    const EDITOR_URL_PREFIX = prefixType === "edit" ? "/edit" : "/preview"
    const urlWithoutPrefix = localizedUrl.replace(EDITOR_URL_PREFIX, "")

    const CMSUrl = getCMSUrlFromLocalizedUrl(urlWithoutPrefix)

    return CMSUrl
}

function getCMSUrlFromLocalizedUrl(localizedUrl: string): string {
    const CULTURE_URL_REGEX = /^\/?(?<culture>[^\/]*)\/?/;
    const allLocalesArray = getAllLocalesArray()
    const prefixArray: string[] = []
    let CMSUrl: string = ""

    allLocalesArray
        .map(locale => prefixArray.push(locale.urlPrefix))

    prefixArray.forEach(prefix => {
        const isoCode = getLocaleByUrlPrefix(prefix).isoCode

        // handle localized homepage url for en
        // result: /en-GB/
        if (localizedUrl === prefix + "/" || localizedUrl === prefix) {
            CMSUrl = `${isoCode}/`
        }

        // handle localized url - "en/some-url"
        // result: /en-GB/some-url/
        const regexCulture = localizedUrl.match(CULTURE_URL_REGEX)
        if (regexCulture?.groups && regexCulture.groups["culture"]) {
            if (regexCulture.groups["culture"] === prefix) {
                // localized url for all prefixes but en
                CMSUrl = `${localizedUrl.replace(CULTURE_URL_REGEX, isoCode + "/")}`
            } else if (isoCode === "en-GB" && (!prefixArray.includes(regexCulture.groups["culture"]))) {
                // localized url for en
                CMSUrl = `${isoCode}${localizedUrl}`.replace(/\/$/, "")
            }
        }
    })

    return CMSUrl
}

function getLocaleByUrlPrefix(urlPrefix: string): langConfig {
    const localeArray = getAllLocalesArray()

    const locale = localeArray.find(locale => locale.urlPrefix === urlPrefix)

    if (!locale) {
        throw `Error when getting Locale by URLPrefix in editURL.ts. Wanted URLPrefix: "${urlPrefix}"`
    }

    return locale
}

function getAllLocalesArray(): langConfig[] {
    const SERVER_PROJECT = process.env.PROJECT ?? "EasyRedmineGatsby"
    const LOCALE_CONFIG = localeConfig
    // localeConfig[SERVER_PROJECT]
    const localeArray: langConfig[] = []

    Object.keys(LOCALE_CONFIG)
        .filter(project => project.includes(SERVER_PROJECT))
        .forEach(locale => {
            localeArray.push(...Object.values(LOCALE_CONFIG[locale]))
        })

    Object.keys(LOCALE_CONFIG)
        .filter(project => project.includes(SERVER_PROJECT + "AI"))
        .forEach(locale => {
            localeArray.push(...Object.values(LOCALE_CONFIG[locale]))
        })

    return localeArray
}
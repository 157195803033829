import { graphql, useStaticQuery } from 'gatsby'
import { getLocalizedData } from 'Shared/utils/localeURL.ts'
const localizedConfigData = getLocalizedData(
  process.env.LOCALE,
  process.env.PROJECT
)

// TODO: We can/should not use this approach, because StaticQuery has no variables, we need to fetch all language variant data in layout query.
export const useHeaderData = () => {
  const {
    orchard: { menu, easySoftwareArticle },
  } = useStaticQuery(
    graphql`
      {
        orchard {
          menu(where: { alias: { alias_ends_with: "/header-navigation" } }) {
            displayText
            alias {
              alias
            }
            menuItemsList {
              menuItems {
                ... on Orchard_ContentMenuItem {
                  render
                  contentMenuItemCTA {
                    html
                  }
                  contentMenuItemDuplicateAlias
                  menuItemsList {
                    menuItems {
                      ... on Orchard_PuxMegamenuCategoryItem {
                        categoryItemDescription
                        categoryItemColumns
                        categoryItemName
                        menuItemsList {
                          menuItems {
                            ... on Orchard_ContentMenuItem {
                              contentMenuItemDuplicateAlias
                              contentMenuItemDuplicateMainItem
                              contentMenuItemDuplicateHide
                              contentType
                              render
                            }
                            ... on Orchard_LinkMenuItem {
                              contentType
                              linkMenuItem {
                                name
                                url
                              }
                            }
                            ... on Orchard_HtmlMenuItem {
                              htmlMenuItem {
                                html
                                url
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          easySoftwareArticle(
            where: { alias: { alias: "redmine-community-support-by-easy" } }
          ) {
            localization {
              localizations {
                ... on Orchard_EasySoftwareArticle {
                  localization {
                    culture
                  }
                  path
                }
              }
            }
          }
        }
      }
    `
  )

  var languageMenu = menu.filter(
    (m) => m.alias.alias.indexOf(localizedConfigData.isoCode) === 0
  )

  var menuData = {
    menuItems:
      languageMenu && languageMenu.length
        ? languageMenu[0].menuItemsList.menuItems
        : menu[0].menuItemsList.menuItems,
  }

  if (easySoftwareArticle && easySoftwareArticle.length) {
    menuData.supportArticle = easySoftwareArticle[0].localization?.localizations?.filter(
      (p) => p.localization?.culture === localizedConfigData.isoCode
    )
  }

  return menuData
}

import { Tooltip } from '@material-ui/core'
import React, { FunctionComponent } from 'react'
import PuxIconBuilder from 'Shared/components/PuxIconBuilder/PuxIconBuilder'

const TrialFormFR: FunctionComponent = () => {
  return (
    <div id='mauticform_wrapper_trialrequest' className='mauticform_wrapper'>
      <form
        autoComplete='false'
        role='form'
        method='post'
        action='https://mautic2018.easyredmine.com/form/submit?formId=4'
        id='mauticform_trialrequest'
        data-mautic-form='trialrequest'
        encType='multipart/form-data'
      >
        <div className='mauticform-error' id='mauticform_trialrequest_error' />
        <div
          className='mauticform-message'
          id='mauticform_trialrequest_message'
        />
        <div className='mauticform-innerform'>
          <div style={{ display: `none` }} className='mautictrial-form-select'>
            <label className='radio radio-container selected'>
              <input
                type='radio'
                name='mauticform[forced_project_id]'
                id='mauticform_input_trialrequest_forced_project_id_2'
                value='0'
                defaultChecked={true}
              />
              <Tooltip
                title={
                  <>
                    <h4>Démo complète</h4>
                    <ul>
                      <li>
                        Un ensemble complet de données de démonstration pour
                        toutes les fonctionnalités
                      </li>
                      <li>
                        15 types d’utilisateurs prédéfinis dans 4 rôles globaux
                      </li>
                      <li>
                        Comprend toutes les fonctionnalités de{` `}
                        <strong>Easy Redmine Platform</strong>
                      </li>
                    </ul>
                  </>
                }
                placement='top'
              >
                <span>
                  {` `}
                  <strong>Démo complète</strong> pour l’exploration détaillée
                  des produits
                  {` `}
                  <PuxIconBuilder icon='InfoIcon' />
                </span>
              </Tooltip>
            </label>
            <label className='radio radio-container'>
              <input
                type='radio'
                name='mauticform[forced_project_id]'
                id='mauticform_input_trialrequest_forced_project_id_1'
                value='1'
                defaultChecked={false}
              />
              <Tooltip
                title={
                  <>
                    <h4>Démo Lite</h4>
                    <ul>
                      <li>Données de démonstration non incluses</li>
                      <li>
                        Seulement 2 types d’utilisateurs prédéfinis, à savoir :
                        un chef de projet et un membre de l’équipe
                      </li>
                      <li>
                        Comprend les fonctionnalités d’{` `}
                        <strong>Easy Redmine Business</strong>
                      </li>
                    </ul>
                  </>
                }
                placement='top'
              >
                <span>
                  {` `}
                  <strong>Démo Lite</strong> pour une familiarisation rapide et
                  facile
                  {` `}
                  <PuxIconBuilder icon='InfoIcon' />
                </span>
              </Tooltip>
            </label>
          </div>
          <div
            className='mauticform-page-wrapper mauticform-page-1'
            data-mautic-form-page={1}
          >
            <div
              id='mauticform_trialrequest_email'
              data-validate='email'
              data-validation-type='email'
              className='mauticform-row mauticform-email mauticform-field-1 mauticform-required'
            >
              <label
                id='mauticform_label_trialrequest_email'
                htmlFor='mauticform_input_trialrequest_email'
                className='mauticform-label'
              >
                Email
              </label>
              <input
                id='mauticform_input_trialrequest_email'
                name='mauticform[email]'
                className='mauticform-input'
                type='email'
                placeholder='Veuillez taper votre adresse email'
              />
              <span className='mauticform-errormsg' style={{ display: `none` }}>
                Ceci est nécessaire.
              </span>
            </div>
            <input
              id='mauticform_input_trialrequest_first_name'
              name='mauticform[first_name]'
              className='mauticform-hidden'
              type='hidden'
            />
            <input
              id='mauticform_input_trialrequest_last_name'
              name='mauticform[last_name]'
              className='mauticform-hidden'
              type='hidden'
            />
            <input
              id='mauticform_input_trialrequest_affiliate_account_id'
              name='mauticform[affiliate_account_id]'
              className='mauticform-hidden'
              type='hidden'
            />
            <input
              id='mauticform_input_trialrequest_affiliate_conversion_id'
              name='mauticform[affiliate_conversion_id]'
              className='mauticform-hidden'
              type='hidden'
            />
            <input
              id='mauticform_input_trialrequest_submission_form_identifier'
              name='mauticform[submission_form_identifier]'
              defaultValue='www.easyredmine.com;demo;'
              className='mauticform-hidden'
              type='hidden'
            />
            <input
              id='mauticform_input_trialrequest_identifier'
              name='mauticform[identifier]'
              className='mauticform-hidden'
              type='hidden'
            />
            <input
              id='mauticform_input_trialrequest_source'
              name='mauticform[source]'
              defaultValue='trial'
              className='mauticform-hidden'
              type='hidden'
            />
            <div
              id='mauticform_trialrequest_recaptcha'
              className='mauticform-row mauticform-div-wrapper mauticform-field-10'
            >
              <label
                id='mauticform_label_trialrequest_recaptcha'
                htmlFor='mauticform_input_trialrequest_recaptcha'
                className='mauticform-label'
              >
                reCaptcha
              </label>
              <input
                id='mauticform_input_trialrequest_recaptcha'
                name='mauticform[recaptcha]'
                className='mauticform-input'
                type='hidden'
              />
              <span
                className='mauticform-errormsg'
                style={{ display: `none` }}
              />
            </div>
            <div
              id='mauticform_trialrequest_submit'
              className='mauticform-row mauticform-button-wrapper mauticform-field-11'
            >
              <button
                type='submit'
                name='mauticform[submit]'
                id='mauticform_input_trialrequest_submit'
                className='mauticform-button btn btn-default'
              >
                Démarrez Mon Easy Redmine
              </button>
            </div>
          </div>
        </div>
        <input
          type='hidden'
          name='mauticform[formId]'
          id='mauticform_trialrequest_id'
          defaultValue={4}
        />
        <input
          type='hidden'
          name='mauticform[return]'
          id='mauticform_trialrequest_return'
        />
        <input
          type='hidden'
          name='mauticform[formName]'
          id='mauticform_trialrequest_name'
          defaultValue='trialrequest'
        />
      </form>
    </div>
  )
}

export default TrialFormFR

import './EasySoftwareWysiwygEditor.scss'

import React, { FunctionComponent, useEffect, useRef, useState } from 'react'

import { PuxWysiwygHtmlBodyType } from '../PuxWysiwyg/PuxWysiwyg';
import EasySoftwareEditorBar from '../EasySoftwareEditorBar/EasySoftwareEditorBar';

// let domWindow: any;

export interface EasySoftwareWysiwygEditorType {
  content: PuxWysiwygHtmlBodyType,
  wysiwygId?: string,
  className?: string
  inline?: boolean
  small?: boolean,
  closeEditor: () => void
  saveEditor: (value: string, EditorRef: any, EditorButtonRef: any) => void
}

const EasySoftwareWysiwygEditor: FunctionComponent<EasySoftwareWysiwygEditorType> = (
  props
) => {
  // const activeWysiwygRef = useRef<HTMLElement>(null)
  const [editor, setEditor] = useState<any>()
  const [active, setActive] = useState<boolean>(false)
  const EditorButtonRef = useRef<HTMLButtonElement | null>(null)
  let numberOfChanges = 0
  const EditorRef = useRef<HTMLDivElement | null>(null)

  const classes = [`WysiwygEditor`]
  classes.push(props.inline ? `WysiwygEditor--inline` : `WysiwygEditor--spaced`)
  if (props.className) {
    classes.push(props.className)
  }
  if (props.small) {
    classes.push(`WysiwygEditor--small`)
  }

  async function editHtml() {
    if (props?.content && props.content?.html) {
      let htmlBody = ``
      htmlBody = props.content.html.replace(
        / src="\/media\//g,
        ` src="${process.env.GATSBY_ORCHARD_API_URL}/media/`
      )

      htmlBody = htmlBody.replace(
        / href="\/media\//g,
        ` href="${process.env.GATSBY_ORCHARD_API_URL}/media/`
      )

      htmlBody = htmlBody
        .replace(/font-size:\s[0-9]{1,}rem[;]{0,1}/g, ``)
        .replace(/font-family: -apple-system([^">]*)&quot;/g, ``)
        .replace(/font-family: -apple-system([^">]*)&quot;/g, ``)
        .replace(/pux-button\s/g, `Button Button--large `)
        .replace(/pux-button-filled/g, `Button--primary`)
        .replace(/pux-button-outlined/g, `Button--secondary`)
        .replace(/pux-button-primary/g, `Button--yellow`)
        .replace(/pux-button-secondary/g, `Button--blue`)

      // if (domWindow) {
      //   const domDocument = domWindow.document
      //   domDocument.body.innerHTML = htmlBody
      //   htmlBody = domModifier(domDocument, true)
      // }

      htmlBody = htmlBody.replace(/(<ul>|<ol>|<\/li>)(?:[\s]+)(<li>|<\/ul>|<\/ol>)/g, '$1$2');
      return htmlBody.replace(/\n\n/g,"")
    }
  }

  async function initializeEditor() {
    const { default: Quill } = await import(`quill`)
    const { default: Delta } = await import(`quill-delta`)
    const { default: QuillBetterTable } = await import(`quill-better-table`)

    const Parchment = Quill.import('parchment');
    const Block = Quill.import('blots/block');
    const BackgroundClass = Quill.import('attributors/class/background')
    const ColorClass = Quill.import('attributors/class/color')
    const SizeStyle = Quill.import('attributors/style/size')
    const TextAlignStyle = Quill.import('attributors/style/align')
    const Embed = Quill.import('blots/embed');


    const ClassBlockAttribute = new Parchment.Attributor('class', 'class', {
      scope: Parchment.Scope.BLOCK,
    });

    const ClassInlineAttribute = new Parchment.Attributor('class', 'class', {
      scope: Parchment.Scope.INLINE,
    });

    Quill.register(BackgroundClass, true)
    Quill.register(ColorClass, true)
    Quill.register(SizeStyle, true)
    Quill.register(TextAlignStyle, true)

    Quill.register({
      'modules/better-table': QuillBetterTable,
    }, true)
    
    Quill.register(ClassBlockAttribute);
    Quill.register(ClassInlineAttribute);

    class BlockBlot extends Block {
      constructor(domNode) {
        super(domNode);
        domNode.setAttribute('class', '');
        this.cache = {};
      }
    }

    BlockBlot.blotName = 'block';

    class SoftLineBreakBlot extends Embed {
      static blotName = 'softbreak';
      static tagName = 'br';  
      static className = 'softbreak';
    }

    Quill.register(SoftLineBreakBlot);
    
    if (!editor || !document.querySelector(`#wysiwyg-${props.wysiwygId}`)) {
      const newEditor = new Quill(`#wysiwyg-${props.wysiwygId}`, {
        debug: 'warn',
        modules: {
          toolbar: {
            container: `#toolbar-${props.wysiwygId}`,
          },
          table: true,
          'better-table': true,
          keyboard: {
            bindings: QuillBetterTable.keyboardBindings
          }
        },
        placeholder: `Insert text..`,
        theme: 'snow',
        output: "html"
      });

      // Toolbar table insert
      const insertTable = () => {
        const tableModule = newEditor.getModule("better-table");
        tableModule.insertTable(2, 2);
      };

      const toolbar = newEditor.getModule("toolbar");
      toolbar.addHandler("table", () => {
        insertTable();
      });
      // Toolbar table insert

      newEditor.clipboard.addMatcher(Node.TEXT_NODE, function(node, delta) {
        return new Delta().insert(node.data);
      });
      newEditor.clipboard.addMatcher("TABLE", function(node, delta) {
        delta.ops.forEach((op) => {
          if (op.attributes && op.attributes.row) {
            op.attributes['table-cell-line'].row = op.attributes.row.toString()
          }
        })
        return delta;
      });
      newEditor.clipboard.addMatcher('STRONG', function(node, delta) {
        return delta.compose(new Delta().retain(delta.length(), { bold: true }));
      });
      newEditor.clipboard.addMatcher('P', function(node, delta) {
        console.log(node)
        console.log(delta)
        return delta;
      });
      newEditor.clipboard.addMatcher('H2', function(node, delta) {
        console.log(node)
        console.log(delta)
        return delta;
      });
      newEditor.clipboard.addMatcher('BR', function(node, delta) {
        let newDelta = new Delta();
        newDelta.insert({softbreak: true});
        return newDelta;
      });
      newEditor.on('editor-change', function (eventName, ...args) {
        if (eventName === 'text-change') {
          numberOfChanges += 1

          if (numberOfChanges > 1) {
            EditorButtonRef?.current?.classList.add(`WysiwygEditor-button--changed`)
          }
        }
      });

      newEditor.on('selection-change', function (range, oldRange, source) {
        if (range) {
          setActive(true)
        } else {
          setActive(false)
        }
      });

      let html = await editHtml();
      newEditor.clipboard.dangerouslyPasteHTML(html);

      let content = document.querySelector(`#wysiwyg-${props.wysiwygId} .ql-editor`);
      let tables = content?.querySelectorAll("table")
      tables?.forEach((table) => {
        table.classList.add("table")
      })

      /*
      document.querySelectorAll('.ql-editor p > br:first-child:last-child').forEach((br) => {
        if (br && br?.parentNode) {
          br.parentNode.remove();
        }
      });
      */

      setEditor(newEditor)
    }
  }

  async function saveData() {
    var result = editor.root.innerHTML.trim()
    let htmlResult = document.createElement("div")
    htmlResult.innerHTML = result
    let listItems = htmlResult.querySelectorAll("li[data-list='bullet']")
    listItems.forEach((li) => {
      let ol = li.parentElement
      if (ol?.nodeName === "OL") {
        let ul = document.createElement("ul")
        ul.innerHTML = ol.innerHTML
        ol.parentElement?.replaceChild(ul, ol)
      }
    })
    result = htmlResult.innerHTML
    await props.saveEditor(result, EditorRef, EditorButtonRef)
  }

  useEffect(() => {
    initializeEditor()

    // if (activeWysiwygRef.current) {
    //   const modalTriggers = activeWysiwygRef.current.querySelectorAll(
    //     `[href*="#pux-modal-"]`
    //   )
    //   modalTriggers.forEach((trigger) => {
    //     trigger.addEventListener(`click`, (e) => {
    //       const thisElement = e.target as HTMLAnchorElement
    //       // Get modal id
    //       const parts = thisElement.href.split(`#pux-modal-`)
    //       const modalId = parts[parts.length - 1]
    //       // Find modal trigger & open modal
    //       const modalButton = document.querySelector(
    //         `#${getModalTriggerId(modalId)}`
    //       ) as HTMLElement
    //       modalButton?.click()
    //     })
    //   })

    // domModifier(activeWysiwygRef.current, true)
    // }

    // if (props.wysiwygId) {
    //   const thisWrapper = document.querySelector(`#wysiwyg-${props.wysiwygId}`)

    //   if (thisWrapper) {
    //     thisWrapper
    //       .querySelectorAll(`a[href*="#"]:not([href*="#pux-modal-"])`)
    //       .forEach((element) => {
    //         element.addEventListener(`click`, (e) => {
    //           e.preventDefault()
    //           const thisElement = e.target as HTMLAnchorElement
    //           const thisAnchor =
    //             thisElement.getAttribute(`href`)?.split(`#`)[1] ?? null
    //           if (thisAnchor) {
    //             document.querySelector(`#${thisAnchor}`)?.scrollIntoView({
    //               behavior: `smooth`,
    //             })
    //           }
    //         })
    //       })
    //   }
    // }
  }, [])

  useEffect(() => {
    if (editor) {
      editor.focus()
    }
  }, [editor])

  // const domModifier = (
  //   documentInstance: any,
  //   isVirtualDOM: boolean
  // ): string => {
  //   documentInstance
  //     .querySelectorAll(`[style*=text-align]`)
  //     .forEach((element) => {
  //       const thisElement = (element as unknown) as HTMLElement
  //       if (thisElement.style.textAlign) {
  //         switch (thisElement.style.textAlign) {
  //           case `right`:
  //             element.classList.add(`Wysiwyg--textRight`)
  //             break
  //           case `center`:
  //             element.classList.add(`Wysiwyg--textCenter`)
  //             break
  //           default:
  //             element.classList.add(`Wysiwyg--textLeft`)
  //         }
  //         thisElement.style.textAlign = ``
  //       }
  //     })

  //   documentInstance.querySelectorAll(`a[href]`).forEach((element) => {
  //     const thisElement = (element as unknown) as HTMLAnchorElement
  //     const href = thisElement.getAttribute(`href`)

  //     if (href && href.startsWith(`#`)) {
  //       return
  //     }

  //     if (href && href.includes(`.`)) {
  //       return
  //     }
  //     if (href && !href?.startsWith(`/`)) {
  //       thisElement.setAttribute(`href`, `/${href}`)
  //       return
  //     }

  //     return
  //   })

  //   documentInstance.querySelectorAll(`img`).forEach((element) => {
  //     const thisElement = (element as unknown) as HTMLImageElement
  //     thisElement.setAttribute(`loading`, `lazy`)

  //     return
  //   })

  //   documentInstance.querySelectorAll(`iframe`).forEach((element) => {
  //     const thisElement = (element as unknown) as HTMLImageElement
  //     thisElement.setAttribute(`loading`, `lazy`)

  //     return
  //   })

  //   if (typeof window === `undefined` && isVirtualDOM) {
  //     return documentInstance.body.innerHTML
  //   }

  //   return ``
  // }

  return (
    <div
      ref={EditorRef}
      className={classes.join(` `)}
    >
      <EasySoftwareEditorBar wysiwygOptions={{ saveHandler: saveData, closeHandler: props.closeEditor, saveButtonRef: EditorButtonRef, id: `toolbar-${props.wysiwygId}`, active: active }} />
      <div id={`wysiwyg-${props.wysiwygId}`} className='Wysiwyg' />
    </div>
  )
}

export default EasySoftwareWysiwygEditor
import './KnowledgeBase.scss'
//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import { HeadProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import EasySoftwareSearch from 'Shared/components/EasySoftwareSearch/EasySoftwareSearch'
import { getLocalizedUrl } from 'Shared/utils/localeURL'
import PuxMetaTagsGatsby from 'Shared/utils/PuxMetaTagsGatsby'
import { GenericPageProps, IEasySoftwareKnowledgeBase } from 'Shared/queries/page-queries'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'

const KnowledgeBase: FunctionComponent<GenericPageProps<IEasySoftwareKnowledgeBase>> = (props) => {
  const pageData = props.pageContext.pageData
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData

  return (
    <Layout
      localizedPath={pageData.localization?.localizations}
      footerData={footerData}
      headerData={headerData}
    >
      <div className='Container KnowledgeBase'>
        <div className='KnowledgeBase-header'>
          <h1>{pageData.displayText}</h1>
          <EasySoftwareSearch
            searchType='knowledgebase'
            setLanguageDialogOpen={() => true}
          />
        </div>
        <div className='KnowledgeBase-container'>
          {props.pageContext?.knowledgeBaseData.map((item, index) => (
            <a
              className='KnowledgeBase-item'
              href={getLocalizedUrl(item.path)}
            >
              <span>
                {index < 9 ? `0` + (index + 1).toString() : index + 1}
              </span>
              <h2>{item.displayTextWithoutNumber}</h2>
            </a>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default KnowledgeBase

export function Head(props: HeadProps<never, IPageContext<IEasySoftwareKnowledgeBase>>) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
}
import { useLocation } from '@reach/router'
import React, { FunctionComponent, useEffect, useState } from 'react'

import Loader from '@componentsfld/core/Loader'
import EasySoftwarePage from '../../../Shared/templates/easySoftwarePage'
import PuxHomePage from '../../../Shared/templates/HomePage/PuxHomePage'
import { getLocalizedData } from '../../../Shared/utils/localeURL'
import { getBuilderService, initBuilderService } from 'Root/Shared/utils/data/puxBuilderService'
import type { CreatePagesArgs, Reporter } from "gatsby"
import { getPrefixedUrl } from 'Shared/utils/edit/editURL'
import { fakeGraphql, fakeReporter, getAuthorizationToken, getEditData, getFakePageProps, getTemplateDataFromUrl } from 'Shared/utils/edit/getEditData'
import ArticlePage from 'Root/Shared/templates/Article/ArticlePage'
import CaseStudyDetail from 'Root/Shared/templates/CaseStudy/CaseStudyDetail'
import FunctionDetail from 'Root/Shared/templates/Function/FunctionDetail'
import NewsDetail from 'Root/Shared/templates/News/NewsDetail'
import CategoryPage from 'Root/Shared/templates/Category/CategoryPage'
import LandingPage from 'Root/Shared/templates/Landing/LandingPage'
import PricingPage from 'Root/Shared/templates/Pricing/PricingPage'
import KnowledgeBaseArticle from 'Root/Shared/templates/KnowledgeBase/KnowledgeBaseArticle'
import FaqDetail from 'Root/Shared/templates/Faq/FaqDetail'
import EasySoftwareEditorBar from 'Root/Shared/components/EasySoftwareEditorBar/EasySoftwareEditorBar'

const localizedConfigData = getLocalizedData(
  process.env.LOCALE as string,
  process.env.PROJECT as string
)

const EditPage: FunctionComponent = () => {
  const fakeBuilderArgs: Partial<CreatePagesArgs> = {
    graphql: fakeGraphql,
    reporter: new fakeReporter() as Reporter
  }

  initBuilderService(fakeBuilderArgs as CreatePagesArgs, localizedConfigData, false)

  const [state, setState] = useState<any>(undefined)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(getBuilderService()?.authorization.access_token ? true : false)

  const location = useLocation();

  useEffect(() => {
    const fetchToken = async () => await getAuthorizationToken()

    fetchToken().then(() => {
      if (getBuilderService().authorization.access_token) {
        setIsAuthenticated(true)
      } else {
        setIsAuthenticated(false)
      }
    })
  }, [])

  useEffect(() => {
    const cmsUrl = getPrefixedUrl(location.pathname, "edit")

    const fetchData = async (templateData, cmsUrl) => {
      return await getEditData({
        contentItemId: templateData.contentItemId ?? ``,
        contentItemVersionId: templateData.contentItemVersionId ?? ``,
        contentType: templateData.contentType ?? ``,
        path: cmsUrl ?? ``,
      })
    }

    if (isAuthenticated) {
      getTemplateDataFromUrl(cmsUrl).then((templateData) => {
        fetchData(templateData, cmsUrl).then(async (pageData) => {
          setState({
            context: pageData.context, pageType: templateData.contentType
          })
        })
      })
    } else {
      setState({ context: undefined, pageType: undefined })
    }
  }, [isAuthenticated])

  function renderLogin() {
    return (
      <>
        <EasySoftwareEditorBar isAuthenticated={false}></EasySoftwareEditorBar>
        <div className='u-marginTop120 u-textCenter'>
          <h1>Log in to your orchard account.</h1>
          <p className='u-marginTop80'>Are you logged in? Please <a href={location.href}>refresh this page 🔃</a>.</p>
        </div>
      </>
    )
  }

  function renderMissingTemplate() {
    return (
      <>
        <div className='u-marginTop120 u-textCenter'>
          <h1>Missing Template.</h1>
          <p className='u-marginTop80'>This page does not have editor feature. <br />Contact Dev Team.</p>
        </div>
      </>
    )
  }

  function renderPreview() {
    const pageProps = getFakePageProps(location, state?.context)
    if (state) {
      switch (state.pageType) {
        case `EasySoftwarePage`:
          return (
            <EasySoftwarePage
              {...pageProps}
            />
          )
        case `PuxHomePage`:
          return (
            <PuxHomePage
              {...pageProps}
            />
          )
        case `EasySoftwareArticle`:
          return (
            <ArticlePage
              {...pageProps}
            />
          )
        case `EasySoftwareCaseStudy`:
          return (
            <CaseStudyDetail
              {...pageProps}

            />
          )
        case `EasySoftwareFunction`:
          return (
            <FunctionDetail
              {...pageProps}
            />
          )
        case `EasySoftwareNews`:
          return (
            <NewsDetail
              {...pageProps}
            />
          )
        case `EasySoftwareCategoryPage`:
          return (
            <CategoryPage
              {...pageProps}
            />
          )
        case `PuxLandingPage`:
          return (
            <LandingPage
              {...pageProps}
            />
          )
        case `EasySoftwarePricingPage`:
          return (
            <PricingPage
              {...pageProps}
            />
          )
        case `EasySoftwareKnowledgeBaseArticle`:
          return (
            <KnowledgeBaseArticle
              {...pageProps}
            />
          )
        case `EasySoftwareFaq`:
          return (
            <FaqDetail
              {...pageProps}
            />
          )
        case undefined:
          return <Loader message='Loading Editor Mode' />
        default:
          return renderMissingTemplate()
      }
    }
    return renderMissingTemplate()
  }

  if (!isAuthenticated) {
    return renderLogin()
  }

  return <div>{renderPreview()}</div>
}

const Edits = () => (
  <EditPage />
)

export default Edits

/* eslint-disable prettier/prettier */
import { useLocation } from '@reach/router'
import PuxLink from 'Shared/utils/PuxLink'
import { decode } from 'html-entities'
import React, { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { getLocalizedUrl } from 'Shared/utils/localeURL'
import { MenuCategory, MenuItem, TopMenuItem } from 'Shared/utils/menuItemsMapper'
import { PuxAnchorLink } from 'Shared/utils/PuxAnchorLink/PuxAnchorLink'

export interface HeaderData {
  menuItems: TopMenuItem[]
}
interface HeaderNavProps {
  headerData: HeaderData
}
export interface renderData {
  url: string
  name: string
  internal?: boolean
}

export interface MenuDuplicate {
  main: MenuItem[]
  nav: MenuItem[]
}

const addHoveredClass = (menuItem: Element) => {
  return menuItem.classList.add(`is-hovered`)
}

const removeHoveredClass = (menuItem: Element) => {
  setTimeout(() => {
    return menuItem.classList.remove(`is-hovered`)
  }, 300)
}

const renderMenuItem = (
  data: MenuItem,
  index: number,
  renderDuplicateAlias = false
) => {
  switch (data.contentType) {
    case `ContentMenuItem`:
      const itemNavData: renderData = JSON.parse(data.render)
      return (
        <li onMouseEnter={(e) => addHoveredClass(e.target as Element)}
          onMouseLeave={(e) => removeHoveredClass(e.target as Element)}
          key={index}>
          <PuxLink
            activeClassName='is-active'
            partiallyActive={true}
            to={`${getLocalizedUrl(itemNavData.url)}`}
          >
            {renderDuplicateAlias
              ? data.contentMenuItemDuplicateAlias
                ? data.contentMenuItemDuplicateAlias
                : decode(itemNavData.name)
              : decode(itemNavData.name)}
          </PuxLink>
        </li>
      )
    case `LinkMenuItem`:
      return (
        <li onMouseEnter={(e) => addHoveredClass(e.target as Element)}
          onMouseLeave={(e) => removeHoveredClass(e.target as Element)}
          key={index}>
          <a href={data.linkMenuItem?.url} target='_blank' rel='noopener'>
            {data.linkMenuItem?.name}
          </a>
        </li>
      )
    default:
      return null
  }
}

const renderCategory = (data: MenuCategory, index: number) => {
  if (data.menuItemsList !== null) {
    const classList = [`Submenu-contentCategory`]

    if (data.categoryItemColumns) classList.push(data.categoryItemColumns)

    return (
      <div className={classList.join(` `)} key={index}>
        <div className='Submenu-contentHeading'>
          {data.categoryItemName}
        </div>
        {data.categoryItemDescription && (
          <div className='Submenu-contentDescription'>
            {data.categoryItemDescription}
          </div>
        )}
        <nav>
          <ul>
            {data.menuItemsList.menuItems.map((menuItem, index) =>
              renderMenuItem(menuItem, index)
            )}
          </ul>
        </nav>
      </div>
    )
  } else {
    return null
  }
}

const renderSubmenu = (data: TopMenuItem, hoverFnc: any) => {
  const isSimple = data.menuItemsList != null && data.menuItemsList.menuItems.length

  const classList = [`Submenu`]

  if (isSimple) classList.push(`is-simple`)

  return (
    <div
      className={classList.join(` `)}
      onMouseEnter={() => hoverFnc(true)}
      onMouseLeave={() => {
        setTimeout(() => {
          hoverFnc(false)
        }, 300)
      }}
    >
      <div className={`Submenu-content`}>
        {data.menuItemsList?.menuItems.map((category, index) =>
          renderCategory(category, index)
        )}
      </div>
    </div>
  )
}

const renderTopMenuItem = (data: TopMenuItem, index: number) => {
  // const [expanded, setExpanded] = React.useState(false)
  const [hovered, setHovered] = React.useState(false)
  const itemNavData: renderData = JSON.parse(data.render)
  const location = useLocation()
  const pathname = location.pathname
  const isInternal = itemNavData.internal ?? true
  const hasSubmenu = data.menuItemsList != null && data.menuItemsList.menuItems.length

  const classList: string[] = []

  if (hasSubmenu) {
    classList.push(`has-submenu`)
  }

  // if (expanded) classList.push(`is-expanded`)

  if (hovered) classList.push(`is-hovered`)

  return (
    <li
      className={classList.join(` `)}
      key={index}
      onMouseEnter={() => {
        setHovered(true)
      }}
      onMouseLeave={() => setHovered(false)}
    >
      {itemNavData.url.startsWith(`#`) &&
        <PuxAnchorLink
          to={pathname + itemNavData.url}
        >
          {decode(itemNavData.name)}
        </PuxAnchorLink>
      }
      {!itemNavData.url.startsWith(`#`) && isInternal &&
        <PuxLink
          activeClassName='is-active'
          partiallyActive={true}
          to={`${getLocalizedUrl(itemNavData.url)}`}
        >
          {decode(itemNavData.name)}
        </PuxLink>
      }
      {!itemNavData.url.startsWith(`#`) && !isInternal &&
        <a href={itemNavData.url}>
          {decode(itemNavData.name)}
        </a>
      }
      {(hasSubmenu) && (renderSubmenu(data, setHovered))}
    </li>
  )
}

const HeaderNav: FunctionComponent<HeaderNavProps> = (props) => {
  return (
    <nav className={`Header-menu Header-menuDefault`}>
      <ul>
        {props?.headerData?.menuItems?.map((item, index) => renderTopMenuItem(item, index))}
      </ul>
    </nav>
  )
}

export default HeaderNav

import './KnowledgeBaseCategory.scss'

import Layout from '@componentsfld/core/Layout'
import { HeadProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import EasySoftwareSearch from 'Shared/components/EasySoftwareSearch/EasySoftwareSearch'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import PuxMetaTagsGatsby from 'Shared/utils/PuxMetaTagsGatsby'
import { GenericPageProps, IEasySoftwareKnowledgeBase, IEasySoftwareKnowledgeBaseCategory } from 'Shared/queries/page-queries'
import { IPageContext } from 'Shared/utils/data/buildTypedPage'

const KnowledgeBaseCategory: FunctionComponent<GenericPageProps<IEasySoftwareKnowledgeBase>> = (props) => {
  const breadcrumbsData = props.pageContext?.breadcrumbs
  const pageData = props.pageContext.pageData
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData
  const widgetData = props.pageContext.widgets?.widgetZone

  return (
    <Layout
      localizedPath={pageData.localization?.localizations}
      footerData={footerData}
      headerData={headerData}
    >
      <div className='Container KnowledgebaseCategory'>
        <EasySoftwareBreadcrumbs items={breadcrumbsData} showAll={true} />
        <div className='KnowledgebaseCategory-header'>
          <h1>
            {props.pageContext.pageData.displayText}
          </h1>
          <EasySoftwareSearch
            searchType='knowledgebase'
            setLanguageDialogOpen={() => true}
          />
        </div>
        <WidgetBuilder widgetBuilderData={widgetData} />
      </div>
    </Layout>
  )
}

export default KnowledgeBaseCategory

export function Head(props: HeadProps<never, IPageContext<IEasySoftwareKnowledgeBaseCategory>>) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
}